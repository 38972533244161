import { pubSub } from "../../infrastructure/pubSub";
import { AuthAPI } from "../../services/hcnApi";
import { TOASTS } from "../../types/toast";

export interface ILoginUseCaseProps {
  username: string;
  password: string;
}
export const loginUseCase = async ({
  username,
  password,
}: ILoginUseCaseProps): Promise<{ token?: string }> => {
  try {
    const res = await AuthAPI.login({ username, password });

    return { token: res.access_token };
  } catch (e) {
    // TODO error handling
    console.log(e);
    pubSub.emit(TOASTS.LOGIN_ERROR, { message: "" });
    return { token: undefined };
  }
};
