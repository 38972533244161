import { pubSub } from "../../infrastructure/pubSub";
import { UserProfileAPI } from "../../services/hcnApi";
import { TOASTS } from "../../types/toast";

export const preUpdatePasswordUseCase = async (): Promise<{
  success: boolean;
}> => {
  try {
    await UserProfileAPI.preUpdatePassword();
    // since we have no response on this API call success, we are setting up a {success: true/false} response here in the usecase
    return { success: true };
  } catch (e) {
    // TODO error handling
    console.log(e);
    pubSub.emit(TOASTS.ERROR, { message: "Pre-update password failed" });
    return { success: false };
  }
};
