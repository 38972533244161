import { useEffect, useMemo, useState } from "react";
import { Modal, Button, DatePicker, Select } from "antd";
import useLoading from "../../../hooks/useLoading";
import { getUsersUseCase } from "../../../useCases/users/getUsersUseCase";
import { EventValue, RangeValue } from "rc-picker/lib/interface";
import { Dayjs } from "dayjs";
import { User } from "../../../domain/device/entities/users";
import { ICheckinParams } from "../../../services/hcnApi";

const { RangePicker } = DatePicker;

interface CheckinModalProps {
  onSubmit: (params: ICheckinParams) => any | undefined;
  onCancel: () => void;
  open: boolean;
  hotelData: { id: string; name: string };
  room: string;
  macAddress: string;
}
const CheckinModal = ({
  onSubmit,
  onCancel,
  open,
  hotelData,
  macAddress,
  room,
}: CheckinModalProps) => {
  const [userId, setUserId] = useState<string | undefined>(undefined);
  const [checkIn, setCheckIn] = useState<EventValue<Dayjs> | null>(null);
  const [checkOut, setCheckOut] = useState<EventValue<Dayjs> | null>(null);

  const [users, setUsers] = useState<User[]>([]);

  const [loading, setLoading] = useState(false);

  const isDisabled = useMemo(() => {
    return !room || !userId || !checkIn || !checkOut || loading;
  }, [room, userId, checkIn, checkOut, loading]);

  const clearState = () => {
    setUserId(undefined);
    setCheckIn(null);
    setCheckOut(null);
    setLoading(false);
  };

  const checkinHandler = async () => {
    if (!room || !userId || !checkIn || !checkOut) return;
    setLoading(true);

    await onSubmit({
      room,
      checkIn: checkIn.valueOf(),
      checkOut: checkOut.valueOf(),
      userId,
      propertyId: hotelData.id,
    });
    setLoading(false);
  };

  const handleDateChange = (
    dates: RangeValue<Dayjs>,
    dateStrings: [string, string]
  ) => {
    if (!dates) {
      setCheckIn(null);
      setCheckOut(null);
      return;
    }
    setCheckIn(dates[0]);
    setCheckOut(dates[1]);
  };

  const [fetchData, loadingUsers] = useLoading<any>(async () => {
    try {
      const users = await getUsersUseCase();

      setUsers(users);
    } catch (err) {
      console.error(err);
    }
  });

  useEffect(() => {
    if (!open) {
      clearState();
    } else {
      fetchData();
    }
  }, [open]);

  return (
    <Modal
      open={open}
      closable={false}
      footer={[
        <Button
          key="confirm"
          disabled={isDisabled}
          onClick={() => checkinHandler()}
        >
          Confirm
        </Button>,
        <Button
          key="cancel"
          onClick={() => {
            clearState();
            onCancel();
          }}
        >
          Cancel
        </Button>,
      ]}
    >
      <p>
        Name: {hotelData.name}, ID: {hotelData.id}
      </p>
      <p>MacAddress: {macAddress}</p>
      <label>
        <p style={{ marginBottom: 2 }}>User</p>
        <Select
          id="user-select"
          style={{ width: "100%" }}
          showSearch
          placeholder="Select a user"
          optionFilterProp="label"
          onChange={(value) => setUserId(value)}
          options={users?.map((user) => ({
            value: user.id,
            label: `${user.firstName} ${user.lastName}`,
          }))}
        />
      </label>

      <label htmlFor="check-dates">
        <div style={{ display: "flex", width: "100%", marginBottom: -10 }}>
          <p style={{ flexGrow: 1 }}>Check-in</p>
          <p style={{ flexGrow: 1 }}>Check-out</p>
        </div>
        <RangePicker
          id="check-dates"
          style={{ width: "100%" }}
          value={[checkIn, checkOut]}
          onChange={handleDateChange}
          allowClear
          placeholder={["Check-in Date", "Check-out Date"]}
        />
      </label>
    </Modal>
  );
};

export default CheckinModal;
