import { Tag } from "antd";
import TagWithColor from "../TagWithColor";
enum BATTERY_LEVEL_COLOR {
  Red = "red",
  Orange = "orange",
  Green = "green",
}

const mapBatteryPercentageToColor = (
  percentage: number
): BATTERY_LEVEL_COLOR => {
  return percentage <= 50
    ? BATTERY_LEVEL_COLOR.Red
    : percentage < 75
    ? BATTERY_LEVEL_COLOR.Orange
    : BATTERY_LEVEL_COLOR.Green;
};
const BatteryLevel: React.FC<{ battery_level?: number }> = ({
  battery_level,
}: {
  battery_level?: number;
}) => {
  if (battery_level === null || battery_level === undefined) {
    return null;
  }
  return (
    <TagWithColor
      colorCodingFn={mapBatteryPercentageToColor}
      value={battery_level}
    >
      {battery_level || 0}%
    </TagWithColor>
  );
};

export default BatteryLevel;
