import { Modal, Button } from "antd";
import { DeviceRoomInfo } from "../../../domain/device/entities/index";

interface CheckoutModalProps {
  onSubmit: () => void;
  onCancel: () => void;
  open: boolean;
  roomInfo: DeviceRoomInfo;
  guestName?: string;
}
const CheckoutModal = ({
  onSubmit,
  onCancel,
  open,
  roomInfo,
  guestName
}: CheckoutModalProps) => {
  return (
    <Modal
      open={open}
      closable={false}
      footer={[
        <Button key="confirm" onClick={() => onSubmit()}>
          Confirm
        </Button>,
        <Button key="cancel" onClick={() => onCancel()}>
          Cancel
        </Button>,
      ]}
    >
      <p>Are you sure you want to checkout this user from this room?</p>
      <p>Name: {guestName}</p>
      <p>MacAddress: {roomInfo.macAddress}</p>
      <p>Floor: {roomInfo.floor}</p>
      <p>Room: {roomInfo.roomNumber}</p>
    </Modal>
  );
};

export default CheckoutModal;
