import { pubSub } from "../../infrastructure/pubSub";
import { IUpdatePasswordParams, UserProfileAPI } from "../../services/hcnApi";
import { TOASTS } from "../../types/toast";

export const updatePasswordUseCase = async ({
  code,
  password,
}: IUpdatePasswordParams): Promise<{
  success: boolean;
}> => {
  try {
    await UserProfileAPI.updatePassword({ code, password });
    // since we have no response on this API call success, we are setting up a {success: true/false} response here in the usecase
    return { success: true };
  } catch (e) {
    // TODO error handling
    console.log(e);
    pubSub.emit(TOASTS.ERROR, { message: "Password update failed" });
    return { success: false };
  }
};
