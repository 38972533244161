import { pubSub } from "../../infrastructure/pubSub";
import { IPreUpdateEmailParams, UserProfileAPI } from "../../services/hcnApi";
import { TOASTS } from "../../types/toast";

export const preUpdateEmailUseCase = async ({
  newEmail,
}: IPreUpdateEmailParams): Promise<{
  success: boolean;
}> => {
  try {
    await UserProfileAPI.preUpdateEmail({ newEmail });
    // since we have no response on this API call success, we are setting up a {success: true/false} response here in the usecase
    return { success: true };
  } catch (e) {
    // TODO error handling
    console.log(e);
    pubSub.emit(TOASTS.ERROR, { message: "Pre-update email failed" });
    return { success: false };
  }
};
