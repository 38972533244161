import React, { useRef } from "react";
import { PieConfig, Pie as AntdPie } from "@ant-design/plots";
import Title from "antd/es/typography/Title";

interface IPieProps {
  title: string;
  data: { type: string; value: number; color: string }[];
  plotClicked?: () => void;
  elementSelected?: (data: any) => void;
}

export const Pie = ({
  title,
  data,
  plotClicked,
  elementSelected,
}: IPieProps) => {
  const config: PieConfig = {
    data,
    appendPadding: 10,
    angleField: "value",
    colorField: "type",
    radius: 1,
    innerRadius: 0.4,
    color: data.map((d) => d.color),
    label: {
      type: "inner",
      offset: "-50%",
      content: "",
      style: {
        textAlign: "center",
        fontSize: 14,
      },
    },
    interactions: [
      {
        type: "element-selected",
      },
      {
        type: "element-active",
      },
    ],
    legend: {
      layout: "horizontal",
      position: "bottom",
    },

    statistic: {
      title: false,
      content: {
        content: "",
      },
    },
    onReady: (plot) => {
      plot.on("plot:click", (evt: any) => {
        if (evt.data) {
          return;
        }
        plotClicked && plotClicked();
      });
      plot.on("element:click", (evt: any) => {
        elementSelected && elementSelected(evt?.data?.data);
      });
    },
  };
  return (
    <div style={{ display: "block", textAlign: "center" }}>
      <Title level={4}>{title}</Title>
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          height: "250px",
        }}
      >
        <AntdPie {...config} />
      </div>
    </div>
  );
};

export default Pie;
