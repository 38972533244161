import React, { useEffect, useState } from "react";
import { DeviceEntity } from "../../domain/device/entities/index";
import DeregisterRoomModal from "./DeregisterRoomModal";
import RegisterRoomModal from "./RegisterRoomModal";
import { Button } from "../Button";
import { Space } from "antd";
import {
  RegisterHandlerParams,
  SubmitRegisterAction,
} from "../../pages/DeviceDetails/deviceDetailsViewModel";
import CheckinModal from "./CheckinModal";
import { ICheckinParams, ICheckoutParams } from "../../services/hcnApi";
import CheckoutModal from "./CheckoutModal";

interface DeviceRoomActionsProps {
  data: DeviceEntity;
  handleDeregister: () => void;
  handleReboot: () => void;
  handleClearCache: () => void;
  handleRegister: (
    params: RegisterHandlerParams
  ) => Promise<SubmitRegisterAction | undefined>;
  handleCheckin: (params: ICheckinParams) => void;
  handleCheckout: (params: ICheckoutParams) => void;
  hotelData: { name: string; id: string };
  guestName?: string;
}
const DeviceRoomActions = ({
  data,
  handleDeregister,
  handleRegister,
  handleReboot,
  handleClearCache,
  hotelData,
  handleCheckin,
  handleCheckout,
  guestName
}: DeviceRoomActionsProps) => {
  const [registerModalOpen, setRegisterModalOpen] = useState(false);
  const [deregisterModalOpen, setDeregisterModalOpen] = useState(false);

  const [checkinModalOpen, setCheckinModalOpen] = useState(false);
  const [checkoutModalOpen, setCheckoutModalOpen] = useState(false);

  return (
    <Space>
      {data?.roomInfo?.floor ? (
        <>
          <DeregisterRoomModal
            onCancel={() => setDeregisterModalOpen(false)}
            open={deregisterModalOpen}
            onSubmit={() => {
              setDeregisterModalOpen(false);
              handleDeregister();
            }}
            hotelData={hotelData!}
            roomInfo={data.roomInfo}
          />
          <Button ghost onClick={() => setDeregisterModalOpen(true)}>
            De-register from room
          </Button>
        </>
      ) : (
        <>
          {hotelData && (
            <>
              <RegisterRoomModal
                onCancel={() => setRegisterModalOpen(false)}
                open={registerModalOpen}
                onSubmit={(params) => handleRegister(params)}
                hotelData={hotelData}
                macAddress={data!.info?.macAddress}
              />
              <Button ghost onClick={() => setRegisterModalOpen(true)}>
                Register Room
              </Button>
            </>
          )}
        </>
      )}
      <Button ghost onClick={() => handleReboot()}>
        Reboot
      </Button>
      <Button ghost onClick={() => handleClearCache()}>
        Clear Cache
      </Button>
      {data.roomInfo.internal && data.roomInfo.roomNumber &&
        (data.roomInfo?.checkIn ? (
          <>
            <CheckoutModal
              onCancel={() => setCheckoutModalOpen(false)}
              open={checkoutModalOpen}
              onSubmit={() => {
                setCheckoutModalOpen(false);
                handleCheckout({
                  propertyId: hotelData.id,
                  room: data.roomInfo.roomNumber,
                });
              }}
              roomInfo={data.roomInfo}
              guestName={guestName}
            />
            <Button ghost onClick={() => setCheckoutModalOpen(true)}>
              Check-out
            </Button>
          </>
        ) : (
          <>
            <CheckinModal
              onCancel={() => setCheckinModalOpen(false)}
              open={checkinModalOpen}
              onSubmit={(params) => {
                setCheckinModalOpen(false);
                handleCheckin(params);
              }}
              hotelData={hotelData!}
              room={data.roomInfo.roomNumber}
              macAddress={data.info.macAddress}
            />
            <Button ghost onClick={() => setCheckinModalOpen(true)}>
              Check-in
            </Button>
          </>
        ))}
    </Space>
  );
};

export default DeviceRoomActions;
