import axios, { AxiosResponse } from "axios";

export const httpFactory = (baseUrl: string) => {
  const instance = axios.create({
    baseURL: baseUrl,
    timeout: 20000,
  });

  const responseBody = (response: AxiosResponse) => response.data;

  return {
    get: (params: {
      url: string;
      data?: { [key: string]: any };
      headers: { [key: string]: any };
    }) =>
      instance
        .get(params.url, { headers: params.headers, data: params.data })
        .then(responseBody),
    post: (params: {
      url: string;
      body: { [key: string]: any };
      headers: { [key: string]: any };
    }) =>
      instance
        .post(params.url, params.body, { headers: params.headers })
        .then(responseBody),
    put: (params: {
      url: string;
      body: { [key: string]: any };
      headers: { [key: string]: any };
    }) =>
      instance
        .put(params.url, params.body, { headers: params.headers })
        .then(responseBody),
    delete: (params: { url: string; headers: { [key: string]: any } }) =>
      instance
        .delete(params.url, { headers: params.headers })
        .then(responseBody),
  };
};
