import { UserProfile } from "../../domain/device/entities/auth";
import { pubSub } from "../../infrastructure/pubSub";
import { UserProfileAPI } from "../../services/hcnApi";
import { TOASTS } from "../../types/toast";

export const getUserProfileUseCase =
  async (): Promise<UserProfile | null> => {
    try {
      const res = await UserProfileAPI.myProfile();
      return res;
    } catch (e) {
      // TODO error handling
      console.log(e);
      pubSub.emit(TOASTS.ERROR, { message: "Could not fetch user profile" });
      return null;
    }
  };
