import Descriptions, { DescriptionsItemType } from "antd/es/descriptions";

interface IItemDescriptionsProps {
  items: (DescriptionsItemType | undefined)[];
}

const ItemDescriptions = ({ items }: IItemDescriptionsProps) => {
  return (
    <Descriptions
      style={{ margin: "15px" }}
      bordered
      column={1}
      size="small"
      items={
        items
          .filter((v) => v)
          .map((v, i) => ({ ...v, key: i })) as DescriptionsItemType[]
      }
    />
  );
};

export default ItemDescriptions;
