import { useRef, useState } from "react";
import { Input, Button, Form, Typography, Spin } from "antd";
import type { InputRef } from "antd";
import { useManageAccountStore } from "../../../store";
import { verifyPasswordUseCase } from "../../../useCases/userProfile/verifyPasswordUseCase";
import { pubSub } from "../../../infrastructure/pubSub";
import { TOASTS } from "../../../types/toast";

const { Text } = Typography;

const PasswordForm = () => {
  const [isCalling, setIsCalling] = useState<boolean>(false);
  const passwordRef = useRef<InputRef | null>(null);
  const { setIsPasswordFormVisible, setIsResetFormsVisible } =
    useManageAccountStore();

  const handleSubmit = async () => {
    if (passwordRef.current) {
      const password = passwordRef.current.input?.value;

      if (!password) return;

      // password verification call
      setIsCalling(true);
      const passwordVerified = await verifyPasswordUseCase({ password });
      setIsCalling(false);

      if (!passwordVerified) {        
        pubSub.emit(TOASTS.ERROR, { message: "Wrong password" });
        return;
      }

      // on success
      setIsPasswordFormVisible(false);
      setIsResetFormsVisible(true);

      // after succesfull validation, clear the password ref for security
      passwordRef.current = null;
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      <Text style={{ fontSize: 14, fontWeight: 300, marginBottom: 20 }}>
        To continue, first verify that it's you
      </Text>
      <Form onFinish={handleSubmit}>
        <Form.Item
          label="Current Password"
          name="password"
          rules={[{ required: true, message: "Please input your password!" }]}
        >
          <Input.Password
            ref={passwordRef}
            placeholder="Enter your password"
            visibilityToggle={true}
            autoFocus
          />
        </Form.Item>
        <Form.Item>
          {isCalling ? (
            <Spin />
          ) : (
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          )}
        </Form.Item>
      </Form>
    </div>
  );
};

export default PasswordForm;
