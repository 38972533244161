import { PropsWithChildren, useState } from "react";

import { NavLink, useLocation } from "react-router-dom";
import { Button, Drawer, Layout, Menu } from "antd";
import HeaderNav from "./HeaderNav";
import { DisplayNotification } from "../../utils/DisplayNotification";

const { Content } = Layout;

const AppLayout = ({ children }: PropsWithChildren) => {
  return (
    <Layout>
      <Content>
        <HeaderNav />
        <DisplayNotification />

        {children}
      </Content>
    </Layout>
  );
};

export default AppLayout;
