import { useEffect, useState } from "react";
import useLoading from "../../hooks/useLoading";
import { useNavigate, useParams } from "react-router-dom";
import { AxiosError } from "axios";
import { pubSub } from "../../infrastructure/pubSub";
import { TOASTS } from "../../types/toast";
import { useAuth } from "../../contexts/auth";
import { getPropertiesUseCase } from "../../useCases/properties/getPropertiesUseCase";
import {
  Property,
  PropertyStats,
} from "../../domain/device/entities/properties";
import { usePropertyStore } from "../../store";
import { getPropertyStats } from "../../useCases/properties/getFleetStatsUseCase";
import { getCitiesUseCase } from "../../useCases/properties/getCitiesUseCase";
import { City } from "../../domain/device/entities/city";
export type TableDataType = Property &
  PropertyStats & { children: (Property & PropertyStats)[] };

const mapPropertiesToCities = ({
  cities,
  properties,
}: {
  cities: City[];
  properties: Property[];
}) => {
  return cities
    .map((city) => ({
      ...city,
      children: properties.filter((p) => p.city === city.id),
    }))
    .filter((c) => c.children.length >= 1);
};
export const useFleetViewModel = (): {
  data?: TableDataType[];
  loading: boolean;
  goToProperty: (property: Property) => void;
  fetchData: () => void;
} => {
  const navigate = useNavigate();
  const { logout } = useAuth();
  const [setProperty, setProperties, storeProperties] = usePropertyStore(
    (state) => [state.setProperty, state.setProperties, state.properties]
  );

  const [data, setData] = useState<TableDataType[]>();
  const [fetchData, loading] = useLoading<any>(async () => {
    try {
      const [properties, cities] = await Promise.all([
        getPropertiesUseCase(),
        getCitiesUseCase(),
      ]);
      setProperties(properties);
      const withStats = await Promise.all(
        properties.map(async (property: Property) => {
          try {
            const stats = await getPropertyStats(property.id);
            return { ...property, ...stats };
          } catch (e) {
            console.log(e);
            return { ...property };
          }
        })
      );
      const mapped = mapPropertiesToCities({ properties: withStats, cities });
      setData(mapped as unknown as TableDataType[]);
    } catch (err) {
      console.error(err);
      if (err instanceof AxiosError && err.response?.status === 401) {
        pubSub.emit(TOASTS.ERROR, {
          message: "Session expired. Please Login.",
        });
        logout();
        navigate("/auth");
        return;
      }
      pubSub.emit(TOASTS.ERROR, {
        message: "Something went wrong",
      });
    }
  });
  const goToProperty = (property: Property) => {
    setProperty(property);
    navigate(`property/${property.id}`);
  };
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (storeProperties?.length === 1) {
      goToProperty(storeProperties[0]);
    }
  }, [storeProperties]);

  return {
    data,
    loading,
    goToProperty,
    fetchData,
  };
};
