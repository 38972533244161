import { pubSub } from "../../infrastructure/pubSub";
import { IPassWordVerification, UserProfileAPI } from "../../services/hcnApi";
import { TOASTS } from "../../types/toast";

export const verifyPasswordUseCase = async ({
  password,
}: IPassWordVerification): Promise<boolean> => {
  try {
    const res = await UserProfileAPI.verifyPassword({ password });
    return res;
  } catch (e) {
    // TODO error handling
    console.log(e);
    pubSub.emit(TOASTS.ERROR, { message: "" });
    return false;
  }
};
