import React, {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { loginUseCase } from "../../useCases/auth/loginUseCase";
import AuthStorage from "../../services/authStorage";
import { getUserPermissionsUseCase } from "../../useCases/auth/getUserPermissionsUseCase";
import { UserState, useAuthStore } from "../../store";
interface ILoginCredentials {
  username: string;
  password: string;
}
export interface IAuthContext {
  login: (props: ILoginCredentials) => Promise<boolean>;
  logout: () => void;
  authenticated: boolean;
  setUser: (user: UserState) => void;
}
const AuthContext = createContext<IAuthContext | null>(null);

export const useAuth = (): IAuthContext => {
  return useContext(AuthContext) as IAuthContext;
};

export const AuthProvider = ({ children }: PropsWithChildren<any>) => {
  const [authenticated, setAuthenticated] = useState(false);
  const setUser = useAuthStore((state) => state.setUser);
  const login = async (props: ILoginCredentials) => {
    const res = await loginUseCase(props);

    if (res.token) {
      AuthStorage.getInstance().setJWTToken(res.token);
      const permissions = await resetPermissions();
      if (!permissions) {
        return false;
      }
      setAuthenticated(true);
      return true;
    }
    // const res =
    return false;
  };

  const resetPermissions = async () => {
    const permissions = await getUserPermissionsUseCase();
    if (!permissions) {
      return false;
    }
    AuthStorage.getInstance().setUserPermissions(permissions);

    return true;
  };

  const logout = () => {
    AuthStorage.getInstance().clear();
    setAuthenticated(false);
  };

  useEffect(() => {
    resetPermissions();
  }, []);

  const context = {
    authenticated,
    login,
    logout,
    setUser,
  };

  return (
    <AuthContext.Provider value={context}>{children}</AuthContext.Provider>
  );
};
