import React, { useEffect, useState } from "react";
import { Modal, Button, Input } from "antd";
import { DeviceRoomInfo } from "../../../domain/device/entities/index";

interface DeregisterRoomModalProps {
  onSubmit: () => void;
  onCancel: () => void;
  open: boolean;
  hotelData: { id: string; name: string };
  roomInfo: DeviceRoomInfo;
}
const DeregisterRoomModal = ({
  onSubmit,
  onCancel,
  open,
  hotelData,
  roomInfo,
}: DeregisterRoomModalProps) => {
  return (
    <Modal
      open={open}
      closable={false}
      footer={[
        <Button key="confirm" onClick={() => onSubmit()}>
          Confirm
        </Button>,
        <Button key="cancel" onClick={() => onCancel()}>
          Cancel
        </Button>,
      ]}
    >
      <p>Are you sure you want to deregister the device from this room?</p>
      <p>
        Name: {hotelData.name}, ID: {hotelData.id}
      </p>
      <p>MacAddress: {roomInfo.macAddress}</p>
      <p>Floor: {roomInfo.floor}</p>
      <p>Room: {roomInfo.roomNumber}</p>
    </Modal>
  );
};

export default DeregisterRoomModal;
