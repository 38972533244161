export interface IPubSubMessage {
  error?: Error;
  data?: any; //TODO Generics
}

export interface IPubSubCbMessage extends IPubSubMessage {
  message: string;
}

export type IXNDAsyncCommandPubSubMessage = IPubSubCbMessage;

type PubSubCbSync = (msg: IXNDAsyncCommandPubSubMessage) => void;
type PubSubCbAsync = (msg: IXNDAsyncCommandPubSubMessage) => Promise<void>;
export type PubSubCb = PubSubCbSync | PubSubCbAsync;

export type PubSubClearListenerFn = () => void;

class PubSub {
  private events: Map<string, PubSubCb[]> = new Map();

  on(channel: string, cb: PubSubCb): PubSubClearListenerFn {
    const cbs = this.events.get(channel) || [];
    cbs.push(cb);
    this.events.set(channel, cbs);

    return () => {
      const CBS = this.events.get(channel);
      if (Array.isArray(CBS)) {
        this.events.set(
          channel,
          CBS.filter((fn) => fn !== cb)
        );
      }
    };
  }

  emit(channel: string, msg: IPubSubCbMessage) {
    const fns = this.events.get(channel);
    if (!fns) return;
    fns.forEach((fn) => fn.apply(null, [msg])); // HINT: this
  }

  destroy(channel: string) {
    this.events.delete(channel);
  }
}

export const pubSub = new PubSub();
