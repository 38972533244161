import React from "react";
import { useLoginPageViewModel } from "./loginPageViewModel";
import { Loader } from "../../components/Loader";
import "./index.css";
const LoginPage = () => {
  const { loading, onFinish, credentials, setCredentials } =
    useLoginPageViewModel();
  return (
    <div className="fullscreen-container">
      {loading && <Loader />}

      <div className="login-container">
        <div className="logo">
          <img
            src="./assets/img/logo.png"
            alt="site logo"
            className="site-logo"
          />
        </div>
        <h1 className="signin-title">Sign in to your account</h1>
        <div className="login-form">
          <label htmlFor="email">
            email
            <input
              type="text"
              id="email"
              className="login-input"
              name="email"
              onChange={(event) =>
                setCredentials({
                  ...credentials,
                  username: event.target.value,
                })
              }
              placeholder=""
            />
          </label>
          <label htmlFor="password">
            password
            <input
              type="password"
              id="password"
              className="login-input"
              name="email"
              autoComplete="on"
              onChange={(event) =>
                setCredentials({
                  ...credentials,
                  password: event.target.value,
                })
              }
              placeholder=""
            />
          </label>

          <button
            onClick={() => onFinish(credentials)}
            id="sign-in"
            className="login-btn"
          >
            Sign In
          </button>
        </div>
      </div>
    </div>
  );

};

export default LoginPage;
