import { useLocation, useNavigate, renderMatches } from "react-router-dom";
import { Header } from "antd/es/layout/layout";
import { useAuth } from "../../contexts/auth";
import { LogoutOutlined } from "@ant-design/icons";
import logo from "../../img/logo.png";
import { usePropertyStore } from "../../store";
import Title from "antd/es/typography/Title";
import { Select } from "antd";
import { Property } from "../../domain/device/entities/properties";

const PropertySelector = () => {
  const navigate = useNavigate();
  const [properties, property, setProperty] = usePropertyStore((state) => [
    state.properties,
    state.property,
    state.setProperty,
  ]);
  if (!properties?.length || !property) {
    return null;
  }
  const goToProperty = (propertyCode: string) => {
    const property = properties.find((p) => p.code === propertyCode)!;
    setProperty(property);
    navigate(`property/${property.id}`);
  };
  return properties?.length === 1 ? (
    <Title
      style={{
        height: "100%",
        margin: 0,
        display: "flex",
        flexDirection: "column",
        placeContent: "space-evenly",
        color: "#ffffff",
      }}
      level={3}
    >
      {property?.name}
    </Title>
  ) : (
    <Select
      labelInValue
      defaultValue={{
        value: property.code,
        label: `${property.name} (${property.code})`,
      }}
      size="large"
      onChange={(selection) => goToProperty(selection.value!)}
      options={properties
        .filter((p) => !!p.code)
        .map((p) => ({
          value: p.code,
          label: `${p?.name} (${p?.code})`,
        }))
        .sort((a, b) => a.label.localeCompare(b.label))}
    />
  );
};

export default PropertySelector;
