import { create } from "zustand";
import { UserProfile } from "../domain/device/entities/auth";
export type UserState = {
  client_id: string;
  user_id: number;
  user_name: string;
  is_admin: boolean;
};
interface AuthStore {
  user?: UserState;
  setUser: (user: UserState) => void;
  userProfile?: UserProfile;
  setUserProfile: (userProfile: UserProfile) => void;
}

export const useAuthStore = create<AuthStore>((set) => ({
  user: undefined,
  userProfile: undefined,

  setUser: (user: UserState) => set({ user }),
  setUserProfile: (userProfile: UserProfile) => set({ userProfile }),
}));
