import React from "react";
import { Area, AreaConfig } from "@ant-design/plots";

interface IAreaPlotProps<T> {
  data: ({
    timestamp: number;
  } & T)[];
  dataKey: string;
  max: number;
}

let weekday = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
export const AreaPlot = <T,>({ data, dataKey, max }: IAreaPlotProps<T>) => {
  const config: AreaConfig = {
    data: data
      .sort((a, b) => +a.timestamp - +b.timestamp)
      .map((d, i) => ({
        ...d,
        timestamp: `${weekday[new Date(d.timestamp).getDay()]} ${new Date(
          d.timestamp
        ).getDate()}/${new Date(d.timestamp).getMonth() + 1}:${new Date(
          d.timestamp
        ).getHours()}:${new Date(d.timestamp).getMinutes()}`,
      })),

    xField: "timestamp",
    yField: dataKey,
    xAxis: {
      range: [0, 1],
    },
    yAxis: {
      max,
    },
  };
  return (
    data && (
      <div>
        <Area {...config} />
      </div>
    )
  );
};

export default AreaPlot;
