import { getDeviceState } from "../../domain/device/models";
import TagWithColor from "../TagWithColor";

const Status: React.FC<{ value?: number }> = ({ value }) => {
  return (
    <TagWithColor
      colorCodingFn={(value?: number) =>
        value === 1 ? "green" : value === 60 ? "red" : "orange"
      }
      value={value}
    >
      {getDeviceState(value).label}
    </TagWithColor>
  );
};

export default Status;
