import { PropsWithChildren } from "react";
import { Outlet, Navigate } from "react-router-dom";
import { useAuth } from "../../contexts/auth";
import { checkAuthTokenUseCase } from "../../useCases/auth/checkAuthTokenUseCase";

export const ProtectedRoute = ({
  redirectPath = "/auth",
  children,
  ...props
}: PropsWithChildren<{ redirectPath?: string }>) => {
  const { authenticated, setUser } = useAuth();

  const validToken = checkAuthTokenUseCase({ setUser });

  // If authorized, return an outlet that will render child elements
  // If not, return element that will navigate to login page
  return authenticated || validToken ? (
    children ? (
      (children as JSX.Element)
    ) : (
      <Outlet />
    )
  ) : (
    <Navigate to={redirectPath} replace />
  );
};
