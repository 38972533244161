import { Tag } from "antd";
import { PropsWithChildren } from "react";

interface Props<T> extends PropsWithChildren {
  value: T;
  defaultColor?: string;
  colorCodingFn: (value: T) => string;
}

const TagWithColor = <T,>({
  value,
  colorCodingFn,
  defaultColor,
  children,
}: Props<T>) => {
  return <Tag color={colorCodingFn(value) || defaultColor}>{children}</Tag>;
};

export default TagWithColor;
