import { DEVICE_STATUS } from "../entities";

export const deviceStateMapping: {
  [key in DEVICE_STATUS]: { description: string; label: string };
} = {
  0: { description: "Device State is Unspecified", label: "Unspecified" },
  1: { description: "Online", label: "Online" },
  20: {
    description:
      "Disabled (device has been factory reset or removed from the Esper Dashboard)",
    label: "Disabled",
  },
  30: {
    description: "Beginning Provisioning",
    label: "Beginning Provisioning",
  },
  40: {
    description: "Configuring Google Play (still provisioning)",
    label: "Provisioning (Google Play)",
  },
  50: {
    description:
      "Applying Policies and Installing Applications (still provisioning)",
    label: "Provisioning / (Policies and Applications)",
  },
  60: { description: "Offline", label: "Offline" },
  70: {
    description:
      "Device is undergoing a Factory Reset (reset started from Dashboard)",
    label: "Undergoing Factory Reset",
  },
  80: {
    description: "Device Onboarding in Progress",
    label: "Onboarding in Progress",
  },
  90: { description: "Device Onboarding Failed", label: "Onboarding Failed" },
  100: { description: "Device Onboarded in Esper", label: "Onboarded" },
  110: {
    description: "Android For Work Account added",
    label: "AndroidForWork added",
  },
  120: { description: "Apps installed", label: "Apps installed" },
  130: { description: "Branding Processed", label: "Branding Processed" },
  140: {
    description: "Permission Policy Processed",
    label: "Permission Policy Processed",
  },
  150: {
    description: "Device Policy Processed",
    label: "Device Policy Processed",
  },
  160: {
    description: "Device Settings Processed",
    label: "Device Settings Processed",
  },
  170: {
    description: "Security Policy Processed",
    label: "Security Policy Processed",
  },
  180: {
    description: "Phone Policy Processed",
    label: "Phone Policy Processed",
  },
  190: {
    description: "Custom Settings Processed",
    label: "Custom Settings Processed",
  },
};

export const getDeviceState = (
  state: DEVICE_STATUS = DEVICE_STATUS.Unspecified
) => {
  return deviceStateMapping[state];
};

export enum GPS_STATE {
  LOCATION_MODE_HIGH_ACCURACY = "LOCATION_MODE_HIGH_ACCURACY",
  LOCATION_MODE_SENSORS_ONLY = "LOCATION_MODE_SENSORS_ONLY",
  LOCATION_MODE_BATTERY_SAVING = "LOCATION_MODE_BATTERY_SAVING",
  LOCATION_MODE_OFF = "LOCATION_MODE_OFF",
  LOCATION_MODE_ON = "LOCATION_MODE_ON",
}
