import { FilterValue, SorterResult } from "antd/es/table/interface";
import { create } from "zustand";
import { persist } from "zustand/middleware";
import { DeviceEntity } from "../domain/device/entities";

export interface PaginationInfo {
  current?: number;
  pageSize?: number;
}
export enum ROOM_FILTER_ENUM {
  NONE,
  REGISTERED,
  EMPTY,
}

interface TableOptionsState {
  showUnregisteredDevices: boolean;
  sortedInfo: SorterResult<DeviceEntity>[];
  timezones: string[];
  paginationInfo: PaginationInfo;
  roomFilter: ROOM_FILTER_ENUM;
  filteredInfo: Record<string, FilterValue | null>;
  totalFiltered: number;
  visibleColumns: string[];
  columnWidths: Record<string, number>;
  columnOrder: string[];
}

interface TableOptionsActions {
  setTotalFiltered: (items: number) => void;

  setSortedInfo: (sortedInfo: TableOptionsState["sortedInfo"]) => void;
  setPaginationInfo: (
    paginationInfo: TableOptionsState["paginationInfo"]
  ) => void;
  setTimezones: (timezones: string[]) => void;
  setRoomFilter: (roomFilter: TableOptionsState["roomFilter"]) => void;
  addSort: (sort: { order: string; columnKey: string }) => void;
  setFilteredInfo: (filteredInfo?: Record<string, FilterValue | null>) => void;
  setExplicitFilter: (params: { key: string; value: any }) => void;
  clearAllSortFilters: () => void;
  setShowUnregisteredDevices: (flag: boolean) => void;
  setVisibleColumns: (columns: string[]) => void;
  setColumnWidths: (columnWidths: Record<string, number>) => void;
  setColumnOrder: (columnOrder: string[]) => void;
}

export const useTableOptionsStore = create(
  persist<TableOptionsState & TableOptionsActions>(
    (set, get) => ({
      totalFiltered: 200,
      showUnregisteredDevices: false,
      sortedInfo: [],
      paginationInfo: { current: 1, pageSize: 10 },
      roomFilter: ROOM_FILTER_ENUM.NONE,
      filteredInfo: {},
      timezones: [],
      visibleColumns: [],
      columnWidths: {},
      columnOrder: [],
      setTimezones: (timezones) => set({ timezones }),
      setTotalFiltered: (total) => set({ totalFiltered: total }),
      setSortedInfo: (sortedInfo: SorterResult<DeviceEntity>[]) =>
        set({ sortedInfo }),
      addSort: (sort: { order: string; columnKey: string }) => {
        const existingFilter = get().sortedInfo.find(
          (s) => s.columnKey === sort.columnKey
        );

        const order =
          existingFilter?.order === "descend" ? "ascend" : "descend";

        const newSortedInfo = get().sortedInfo.filter(
          (s) => s.columnKey !== sort.columnKey
        );
        set({
          sortedInfo: [...newSortedInfo, { order, columnKey: sort.columnKey }],
        });
      },
      setPaginationInfo: (paginationInfo: PaginationInfo) =>
        set({ paginationInfo }),
      setRoomFilter: (roomFilter: ROOM_FILTER_ENUM) => set({ roomFilter }),
      setFilteredInfo: (filteredInfo) =>
        set({ filteredInfo: { ...filteredInfo } }),
      setExplicitFilter: ({
        key,
        value,
      }: {
        key: string;
        value: any[] | null;
      }) => set({ filteredInfo: { ...get().filteredInfo, [key]: value } }),
      clearAllSortFilters: () => {
        set({
          filteredInfo: {},
          roomFilter: ROOM_FILTER_ENUM.NONE,
          sortedInfo: [],
        });
      },
      setShowUnregisteredDevices: (flag) =>
        set({ showUnregisteredDevices: flag }),
      setVisibleColumns: (columns) => set({ visibleColumns: columns }),
      setColumnWidths: (columnWidths) => set({ columnWidths }),
      setColumnOrder: (columnOrder) => set({ columnOrder }),
    }),
    { name: `table-options` }
  )
);
