import { UserPermissions } from "../../domain/device/entities/auth";
import Storage from "../../infrastructure/storage";

export enum TOKENS {
  JWT_TOKEN = "token",
  PERMISSIONS = "permissions",
}

export default class AuthStorage extends Storage<TOKENS> {
  private static instance?: AuthStorage;

  private constructor() {
    super();
  }

  public static getInstance() {
    if (!this.instance) {
      this.instance = new AuthStorage();
    }

    return this.instance;
  }

  public getJWTToken() {
    return this.get(TOKENS.JWT_TOKEN);
  }

  public setJWTToken(token: string) {
    this.set(TOKENS.JWT_TOKEN, token);
  }

  public getUserPermissions(): UserPermissions | null {
    const res = this.get(TOKENS.PERMISSIONS);
    if (res) {
      return JSON.parse(res);
    }
    return null;
  }

  public setUserPermissions(permissions: UserPermissions) {
    this.set(TOKENS.PERMISSIONS, JSON.stringify(permissions));
  }

  public clear() {
    this.clearItems([TOKENS.JWT_TOKEN]);
  }
}
