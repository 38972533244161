import React, { useEffect, useState } from "react";
import useLoading from "../../hooks/useLoading";
import {
  DeviceEntity,
  IBatteryHistory,
  IWifiHistory,
} from "../../domain/device/entities";
import { useNavigate, useParams } from "react-router-dom";
import { getDeviceByNameUseCase } from "../../useCases/devices/getDeviceByNameUseCase";
import { AxiosError } from "axios";
import { pubSub } from "../../infrastructure/pubSub";
import { TOASTS } from "../../types/toast";
import { registerRoomUseCase } from "../../useCases/devices/registerRoomUseCase";
import { deregisterRoomUseCase } from "../../useCases/devices/deregisterRoomUseCase.ts";
import {
  ASSET_TYPE,
  PERMISSION_TYPE,
  UserPermissions,
} from "../../domain/device/entities/auth";
import AuthStorage from "../../services/authStorage";
import { useAuth } from "../../contexts/auth";
import { getDeviceBatteryHistoryUseCase } from "../../useCases/devices/getBatteryHistoryByDeviceNameUseCase";
import { getDeviceWifiHistoryUseCase } from "../../useCases/devices/getWifiHistoryByDeviceNameUseCase";
import { rebootDeviceUseCase } from "../../useCases/devices/rebootDeviceUseCase.ts";
import { clearDeviceCacheUseCase } from "../../useCases/devices/clearDeviceCacheUseCase.ts";
import { useAuthStore, UserState } from "../../store";
import { getRoomDevicesUseCase } from "../../useCases/devices/getRoomDevicesUseCase.ts";
import { updateDeviceDescriptionUseCase } from "../../useCases/devices/updateDeviceDescription";
import { ICheckinParams, ICheckoutParams } from "../../services/hcnApi";
import { checkinUseCase } from "../../useCases/properties/checkinUseCase";
import { checkoutUseCase } from "../../useCases/properties/checkoutUseCase";
export enum REGISTER_ACTION_STATE {
  HAS_ONE_DEVICE,
}
export interface RegisterHandlerParams {
  floor: string;
  room: string;
  description?: string;
  force?: boolean;
  askedForStrategy?: boolean;
}
export interface SubmitRegisterAction {
  macAddress: string;
  status: REGISTER_ACTION_STATE;
}

export const useDeviceDetailsViewModel = (): {
  data?: DeviceEntity;
  hotelData?: { id: string; name: string };
  loading: boolean;
  handleBack: () => void;
  handleRegister: (
    params: RegisterHandlerParams
  ) => Promise<SubmitRegisterAction | undefined>;
  handleDeregister: () => void;
  handleUpdateDescription: () => Promise<any>;
  loadingDeviceAction: boolean;
  permissions?: UserPermissions;
  batteryHistory?: IBatteryHistory;
  wifiHistory?: IWifiHistory;
  handleReboot: () => void;
  handleClearCache: () => void;
  titleClick: () => void;
  description?: string;
  guestName?: string;
  setDescription: (description: string) => void;
  handleCheckin: (params: ICheckinParams) => void;
  handleCheckout: (params: ICheckoutParams) => void;
} => {
  let { name, hotelId } = useParams<string>();
  const navigate = useNavigate();
  const handleBack = () => {
    navigate(`/property/${hotelData?.id}`);
  };
  const { logout } = useAuth();
  const user = useAuthStore((state) => state.user);
  const [data, setData] = useState<DeviceEntity>();
  const [permissions, setPermissions] = useState<UserPermissions>();
  const [batteryHistory, setBatteryHistory] = useState<
    IBatteryHistory | undefined
  >();

  const [wifiHistory, setWifiHistory] = useState<IWifiHistory | undefined>();
  const [hotelData, setHotelData] = useState<
    { id: string; name: string } | undefined
  >();
  const [guestName, setGuestName] = useState<string>();
  const [loadingDeviceAction, setLoadingDeviceAction] =
    useState<boolean>(false);
  const [description, setDescription] = useState<string>();

  const [fetchData, loading] = useLoading<any>(async () => {
    if (!name || !hotelId) {
      navigate("/");
      return;
    }
    try {
      const res = await getDeviceByNameUseCase({ name, hotelId: +hotelId });

      setData(res.data);
      setDescription(res.data?.roomInfo?.description);
      setHotelData(res.hotelData);
      setGuestName(res.guestName?.replaceAll('"', ""));
    } catch (err) {
      console.error(err);
      if (err instanceof AxiosError && err.response?.status === 401) {
        pubSub.emit(TOASTS.ERROR, {
          message: "Session expired. Please Login.",
        });
        logout();
        navigate("/auth");
        return;
      }
      pubSub.emit(TOASTS.ERROR, {
        message: "Something went wrong",
      });
    }
  });
  const handleRegister = async (
    params: RegisterHandlerParams
  ): Promise<SubmitRegisterAction | undefined> => {
    try {
      if (!params.askedForStrategy) {
        const res = await getRoomDevicesUseCase({
          ...params,
          hotelId: hotelData!.id,
        });
        if (res.length === 1) {
          return {
            macAddress: res[0].macAddress,
            status: REGISTER_ACTION_STATE.HAS_ONE_DEVICE,
          };
        }
      }
      setLoadingDeviceAction(true);
      await registerRoomUseCase({
        ...params,
        hotelId: hotelData!.id,
        macAddress: data!.info?.macAddress,
        force: params.askedForStrategy ? !!params.force : false,
      });

      await fetchData();
      setLoadingDeviceAction(false);
    } catch (err) {
      console.error(err);
      if (err instanceof AxiosError && err.response?.status === 401) {
        pubSub.emit(TOASTS.ERROR, {
          message:
            "Unauthorized action or session expired. Please Login again.",
        });
        logout();

        navigate("/auth");
        return;
      }
      pubSub.emit(TOASTS.ERROR, {
        message: "Something went wrong",
      });
      setLoadingDeviceAction(false);
    }
  };
  const handleDeregister = async () => {
    try {
      setLoadingDeviceAction(true);
      await deregisterRoomUseCase({
        hotelId: hotelData!.id,
        macAddress: data!.info?.macAddress!,
      });
      await fetchData();
      setLoadingDeviceAction(false);
    } catch (err) {
      console.error(err);
      if (err instanceof AxiosError && err.response?.status === 401) {
        pubSub.emit(TOASTS.ERROR, {
          message:
            "Unauthorized action or session expired. Please Login again.",
        });
        logout();

        navigate("/auth");
        return;
      }
      pubSub.emit(TOASTS.ERROR, {
        message: "Something went wrong",
      });
      setLoadingDeviceAction(false);
    }
  };

  const handleUpdateDescription = async () => {
    try {
      if (description !== "" && !description) {
        return;
      }
      setLoadingDeviceAction(true);
      await updateDeviceDescriptionUseCase({
        hotelId: hotelData!.id,
        macAddress: data!.info?.macAddress!,
        description,
      });
      await fetchData();
      setLoadingDeviceAction(false);
    } catch (err) {
      console.error(err);
      if (err instanceof AxiosError && err.response?.status === 401) {
        pubSub.emit(TOASTS.ERROR, {
          message:
            "Unauthorized action or session expired. Please Login again.",
        });
        logout();

        navigate("/auth");
        return;
      }
      pubSub.emit(TOASTS.ERROR, {
        message: "Something went wrong",
      });
      setLoadingDeviceAction(false);
    }
  };

  const handleReboot = async () => {
    try {
      if (!name || !hotelId) {
        navigate("/");
        return;
      }
      setLoadingDeviceAction(true);
      await rebootDeviceUseCase({ name, hotelId: +hotelId });
      await fetchData();
      setLoadingDeviceAction(false);
    } catch (err) {
      console.error(err);
      if (err instanceof AxiosError && err.response?.status === 401) {
        pubSub.emit(TOASTS.ERROR, {
          message:
            "Unauthorized action or session expired. Please Login again.",
        });
        logout();

        navigate("/auth");
        return;
      }
      pubSub.emit(TOASTS.ERROR, {
        message: "Something went wrong",
      });
      setLoadingDeviceAction(false);
    }
  };

  const handleClearCache = async () => {
    try {
      if (!name || !hotelId) {
        navigate("/");
        return;
      }
      setLoadingDeviceAction(true);
      await clearDeviceCacheUseCase({ name, hotelId: +hotelId });
      await fetchData();
      setLoadingDeviceAction(false);
    } catch (err) {
      console.error(err);
      if (err instanceof AxiosError && err.response?.status === 401) {
        pubSub.emit(TOASTS.ERROR, {
          message:
            "Unauthorized action or session expired. Please Login again.",
        });
        logout();

        navigate("/auth");
        return;
      }
      pubSub.emit(TOASTS.ERROR, {
        message: "Something went wrong",
      });
      setLoadingDeviceAction(false);
    }
  };

  const handleCheckin = async (params: ICheckinParams) => {
    try {
      setLoadingDeviceAction(true);
      await checkinUseCase(params);
      await fetchData();
    } catch (err) {
      console.error(err);
      if (err instanceof AxiosError && err.response?.status === 401) {
        pubSub.emit(TOASTS.ERROR, {
          message:
            "Unauthorized action or session expired. Please Login again.",
        });
        logout();

        navigate("/auth");
        return;
      }
      pubSub.emit(TOASTS.ERROR, {
        message: err instanceof Error ? err.message : "Something went wrong",
      });
    } finally {
      setLoadingDeviceAction(false);
    }
  };

  const handleCheckout = async (params: ICheckoutParams) => {
    try {
      setLoadingDeviceAction(true);
      await checkoutUseCase(params);
      await fetchData();
    } catch (err) {
      console.error(err);
      if (err instanceof AxiosError && err.response?.status === 401) {
        pubSub.emit(TOASTS.ERROR, {
          message:
            "Unauthorized action or session expired. Please Login again.",
        });
        logout();

        navigate("/auth");
        return;
      }
      pubSub.emit(TOASTS.ERROR, {
        message: err instanceof Error ? err.message : "Something went wrong",
      });
    } finally {
      setLoadingDeviceAction(false);
    }
  };

  const getPermissionsFromStore = () => {
    const permissions = AuthStorage.getInstance().getUserPermissions();
    setPermissions(permissions!);
  };

  const fetchBatteryHistory = async () => {
    if (!name || !hotelId) {
      return;
    }
    const history = await getDeviceBatteryHistoryUseCase({
      name,
      hotelId: +hotelId,
    });
    setBatteryHistory(history);
  };

  const fetchWifiHistory = async () => {
    if (!name || !hotelId) {
      return;
    }
    const history = await getDeviceWifiHistoryUseCase({
      name,
      hotelId: +hotelId,
    });
    setWifiHistory(history);
  };

  function hasUserPermissionsToAccessEsper(
    user: UserState | undefined,
    permissions: UserPermissions | undefined
  ) {
    return (
      user?.is_admin ||
      permissions?.assets
        .find((p) => p.name === ASSET_TYPE.device_dashboard)
        ?.permissions.includes(PERMISSION_TYPE.Publish) ||
      user?.user_name.endsWith("@hcn-inc.com")
    );
  }

  const titleClick = () => {
    if (hasUserPermissionsToAccessEsper(user, permissions)) {
      window.open(
        `https://hcninc.esper.cloud/devices/${data?.info.id}/information`,
        "_blank"
      );
    }
  };

  useEffect(() => {
    getPermissionsFromStore();
    fetchData();
    fetchBatteryHistory();
    fetchWifiHistory();
  }, []);
  return {
    handleReboot,
    handleClearCache,
    data,
    loading,
    handleBack,
    loadingDeviceAction,
    handleDeregister,
    handleRegister,
    hotelData,
    permissions,
    batteryHistory,
    wifiHistory,
    titleClick,
    handleUpdateDescription,
    description,
    setDescription,
    guestName,
    handleCheckin,
    handleCheckout,
  };
};
