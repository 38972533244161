import { adminApiConfig } from "../../config";
import {
  BATTERY_LEVEL,
  DeviceEntity,
  IBatteryHistory,
  IWifiHistory,
} from "../../domain/device/entities";
import {
  UserPermissions,
  UserProfile,
} from "../../domain/device/entities/auth";
import { PropertyStats } from "../../domain/device/entities/properties";
import { httpFactory } from "../../infrastructure/http";
import AuthStorage from "../authStorage";

const LOGIN_GRANT_TYPE = "password";
const httpClient = httpFactory(adminApiConfig.baseUrl);
interface IPaginationParams {
  limit?: number;
  offset?: number;
}

export const AuthAPI = {
  login: async ({
    username,
    password,
  }: {
    username: string;
    password: string;
  }): Promise<{
    access_token: string;
    expires_in: number;
    token_type: "bearer";
  }> => {
    const basicAuthHeader = `Basic ${btoa(
      adminApiConfig.basicAuthUsername + ":" + adminApiConfig.basicAuthPassword
    )}`;
    const headers = { Authorization: basicAuthHeader };
    const response = await httpClient.post({
      url: "/oauth/token",
      body: {
        grant_type: LOGIN_GRANT_TYPE,
        username,
        password,
      },
      headers,
    });

    return response;
  },
  myPermissions: async (): Promise<UserPermissions> => {
    const jwt = AuthStorage.getInstance().getJWTToken();
    const headers = { Authorization: `Bearer ${jwt}` };

    const data = await httpClient.get({
      url: `/my-permissions`,
      headers,
    });

    return data;
  },
};

export interface IPassWordVerification {
  password: string;
}

export interface IUpdatePasswordParams {
  code: string;
  password: string;
}

export interface IPreUpdateEmailParams {
  newEmail: string;
}

export interface IUpdateEmailParams {
  code: string;
}

export const UserProfileAPI = {
  myProfile: async (): Promise<UserProfile> => {
    const jwt = AuthStorage.getInstance().getJWTToken();
    const headers = { Authorization: `Bearer ${jwt}` };

    const data = await httpClient.get({
      url: `/my-profile`,
      headers,
    });

    return data;
  },
  verifyPassword: async ({ password }: IPassWordVerification): Promise<any> => {
    const jwt = AuthStorage.getInstance().getJWTToken();

    const headers = { Authorization: `Bearer ${jwt}` };
    const response = await httpClient.post({
      url: `/password/verification`,
      body: { password },
      headers,
    });

    return response;
  },
  preUpdatePassword: async (): Promise<any> => {
    const jwt = AuthStorage.getInstance().getJWTToken();

    const headers = { Authorization: `Bearer ${jwt}` };
    const response = await httpClient.put({
      url: `/my-password/pre`,
      body: {},
      headers,
    });

    return response;
  },
  updatePassword: async ({
    code,
    password,
  }: IUpdatePasswordParams): Promise<any> => {
    const jwt = AuthStorage.getInstance().getJWTToken();

    const headers = { Authorization: `Bearer ${jwt}` };
    const response = await httpClient.put({
      url: `/my-password`,
      body: { code, password },
      headers,
    });

    return response;
  },
  preUpdateEmail: async ({ newEmail }: IPreUpdateEmailParams): Promise<any> => {
    const jwt = AuthStorage.getInstance().getJWTToken();

    const headers = { Authorization: `Bearer ${jwt}` };
    const response = await httpClient.put({
      url: `/my-email/pre`,
      body: { newEmail },
      headers,
    });

    return response;
  },
  updateEmail: async ({ code }: IUpdateEmailParams): Promise<any> => {
    const jwt = AuthStorage.getInstance().getJWTToken();

    const headers = { Authorization: `Bearer ${jwt}` };
    const response = await httpClient.put({
      url: `/my-email`,
      body: { code },
      headers,
    });

    return response;
  },
};

export interface IGetDevicesFilters {
  device_name?: string;
  room_number?: string;
  floor?: string;
  battery_level?: BATTERY_LEVEL;
}

export interface IGetDevicesParams {
  pagination?: IPaginationParams;
  filters?: IGetDevicesFilters;
}

export interface IGetDeviceByNameParams {
  name: string;
  hotelId: number;
}

export interface IRegisterRoomParams {
  hotelId: string;
  room: string;
  macAddress: string;
  floor: string;
  force: boolean;
  description?: string;
}

export interface IUpdateDeviceRoomDescription {
  hotelId: string;
  macAddress: string;
  description: string;
}

export interface IGetRoomDevicesParams {
  room: string;
  hotelId: string;
}

export interface IDeregisterRoomParams {
  hotelId: string;
  macAddress: string;
}

export interface ICheckinParams {
  propertyId: string;
  room: string;
  userId: string;
  checkIn: number;
  checkOut: number;
}

export interface ICheckoutParams {
  propertyId: string;
  room: string;
}

export const DevicesAPI = {
  getDevices: async ({
    hotelId,
  }: {
    hotelId: number;
  }): Promise<{
    results: DeviceEntity[];
    total: number;
    offset: number;
    pageSize: number;
  }> => {
    const jwt = AuthStorage.getInstance().getJWTToken();
    const headers = { Authorization: `Bearer ${jwt}` };

    const data = await httpClient.get({
      url: `/hotel/${hotelId}/devices`,
      headers,
    });

    return data;
  },

  getDeviceByName: async ({
    name,
    hotelId,
  }: IGetDeviceByNameParams): Promise<{
    data: DeviceEntity;
    hotelData?: { id: string; name: string };
    guestName?: string;
  }> => {
    const jwt = AuthStorage.getInstance().getJWTToken();

    const headers = { Authorization: `Bearer ${jwt}` };
    const response = await httpClient.get({
      url: `/hotel/${hotelId}/devices/${name}`,
      headers,
    });

    return response;
  },

  getDeviceBatteryHistory: async ({
    name,
    hotelId,
  }: IGetDeviceByNameParams): Promise<IBatteryHistory> => {
    const jwt = AuthStorage.getInstance().getJWTToken();

    const headers = { Authorization: `Bearer ${jwt}` };
    const response = await httpClient.get({
      url: `/hotel/${hotelId}/devices/${name}/battery`,
      headers,
    });

    return response?.data?.history;
  },

  getDeviceWifiHistory: async ({
    name,
    hotelId,
  }: IGetDeviceByNameParams): Promise<IWifiHistory> => {
    const jwt = AuthStorage.getInstance().getJWTToken();

    const headers = { Authorization: `Bearer ${jwt}` };
    const response = await httpClient.get({
      url: `/hotel/${hotelId}/devices/${name}/wifi`,
      headers,
    });

    return response?.data?.history;
  },

  getRoomDevices: async ({
    hotelId,
    room,
  }: IGetRoomDevicesParams): Promise<
    {
      floor: string;
      hotelId: number;
      macAddress: string;
      meta: any;
      room: string;
    }[]
  > => {
    const jwt = AuthStorage.getInstance().getJWTToken();

    const headers = { Authorization: `Bearer ${jwt}` };
    const response = await httpClient.get({
      url: `/properties/${hotelId}/rooms/${room}/devices`,
      headers,
    });

    return response;
  },

  registerRoom: async ({
    hotelId,
    floor,
    macAddress,
    room,
    force,
    description,
  }: IRegisterRoomParams): Promise<any> => {
    const jwt = AuthStorage.getInstance().getJWTToken();

    const headers = { Authorization: `Bearer ${jwt}` };
    const response = await httpClient.post({
      url: `/properties/${hotelId}/room-devices?force=${!!force}`,
      headers,
      body: {
        floor,
        macAddress,
        room,
        meta: description ? { description } : {},
      },
    });

    return response;
  },

  updateDeviceRoomDescription: async ({
    hotelId,
    macAddress,
    description,
  }: IUpdateDeviceRoomDescription): Promise<any> => {
    const jwt = AuthStorage.getInstance().getJWTToken();

    const headers = { Authorization: `Bearer ${jwt}` };
    const response = await httpClient.put({
      url: `/properties/${hotelId}/mac-address/${macAddress}/description`,
      headers,
      body: { description },
    });

    return response;
  },

  deregisterRoom: async ({
    hotelId,
    macAddress,
  }: IDeregisterRoomParams): Promise<any> => {
    const jwt = AuthStorage.getInstance().getJWTToken();

    const headers = { Authorization: `Bearer ${jwt}` };
    const response = await httpClient.delete({
      url: `/properties/${hotelId}/mac-address/${macAddress}`,
      headers,
    });

    return response;
  },

  rebootDevice: async ({
    name,
    hotelId,
  }: IGetDeviceByNameParams): Promise<any> => {
    const jwt = AuthStorage.getInstance().getJWTToken();

    const headers = { Authorization: `Bearer ${jwt}` };
    const response = await httpClient.post({
      url: `/hotel/${hotelId}/devices/${name}/reboot`,
      body: {},
      headers,
    });

    return response;
  },
  clearDeviceCache: async ({
    name,
    hotelId,
  }: IGetDeviceByNameParams): Promise<any> => {
    const jwt = AuthStorage.getInstance().getJWTToken();

    const headers = { Authorization: `Bearer ${jwt}` };
    const response = await httpClient.post({
      url: `/hotel/${hotelId}/devices/${name}/clear-cache`,
      body: {},
      headers,
    });

    return response;
  },
};

export const PropertiesAPI = {
  getProperties: async (): Promise<any> => {
    const jwt = AuthStorage.getInstance().getJWTToken();

    const headers = { Authorization: `Bearer ${jwt}` };
    const response = await httpClient.get({
      url: `/properties`,
      headers,
    });

    return response;
  },
  getFleetStats: async ({
    hotelId,
  }: {
    hotelId: number;
  }): Promise<PropertyStats> => {
    const jwt = AuthStorage.getInstance().getJWTToken();

    const headers = { Authorization: `Bearer ${jwt}` };
    const response = await httpClient.get({
      url: `/hotel/${hotelId}/fleet-stats`,
      headers,
    });

    return response;
  },
  getCities: async (): Promise<any> => {
    const jwt = AuthStorage.getInstance().getJWTToken();

    const headers = { Authorization: `Bearer ${jwt}` };
    const response = await httpClient.get({
      url: `/cities`,
      headers,
    });

    return response;
  },
  checkin: async ({
    propertyId,
    room,
    userId,
    checkIn,
    checkOut,
  }: ICheckinParams) => {
    const jwt = AuthStorage.getInstance().getJWTToken();

    const headers = { Authorization: `Bearer ${jwt}` };

    const response = await httpClient.put({
      url: `/properties/${propertyId}/rooms/${room}/checkin`,
      body: {
        userId,
        checkIn,
        checkOut,
      },
      headers,
    });

    return response;
  },

  checkout: async ({
    propertyId,
    room,
  }: {
    propertyId: string;
    room: string;
  }) => {
    const jwt = AuthStorage.getInstance().getJWTToken();

    const headers = { Authorization: `Bearer ${jwt}` };

    const response = await httpClient.put({
      url: `/properties/${propertyId}/rooms/${room}/checkout`,
      body: {},
      headers,
    });

    return response;
  },
};

export const UsersAPI = {
  getUsers: async (): Promise<any> => {
    const jwt = AuthStorage.getInstance().getJWTToken();

    const headers = { Authorization: `Bearer ${jwt}` };
    const response = await httpClient.get({
      url: `/users`,
      headers,
    });

    return response;
  },
};
