import { commonConfig } from "../../../config";
import { DeviceEntity } from "../../../domain/device/entities";
import { generateCsvBlob } from "../../../services/csvService";
import { downloadFileFromBlob } from "../../../services/downloadService";
import { headerArray, mapper } from "./helper";

const fileExtension = "csv";
const delimiter = ",";

export const exportCsvUseCase = async (params: { data: DeviceEntity[] }) => {
  const blob = generateCsvBlob<DeviceEntity>({
    data: params.data,
    mapper,
    headerData: headerArray,
    delimiter,
  });

  const fileNameWithExtension = `${commonConfig.csvExportFileName.trim()}.${fileExtension}`;
  downloadFileFromBlob(blob, fileNameWithExtension);
};
