// src/features/auth/AuthAPI.js

import { UserPermissions } from "../../domain/device/entities/auth";
import { pubSub } from "../../infrastructure/pubSub";
import { AuthAPI } from "../../services/hcnApi";
import { TOASTS } from "../../types/toast";

export interface ILoginUseCaseProps {
  username: string;
  password: string;
}
export const getUserPermissionsUseCase =
  async (): Promise<UserPermissions | null> => {
    try {
      const res = await AuthAPI.myPermissions();
      return res;
    } catch (e) {
      // TODO error handling
      console.log(e);
      pubSub.emit(TOASTS.ERROR, { message: "Could not fetch permissions" });
      return null;
    }
  };
