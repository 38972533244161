import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";

import LoginPage from "./pages/Login";
import ListPage from "./pages/DevicesList";
import DetailPage from "./pages/DeviceDetails";
import { AuthProvider } from "./contexts/auth";
import { ProtectedRoute } from "./utils/ProtectedRoute";
import ErrorBoundary from "./utils/ErrorBoundary";

import AppLayout from "./components/Layout";
import FleetPage from "./pages/Fleet";

const App: React.FC = () => {
  return (
    <ErrorBoundary>
      <AuthProvider>
        <Router>
          <AppLayout>
            <Routes>
              <Route path="/auth" element={<LoginPage />} />
              <Route element={<ProtectedRoute />}>
                <Route path="/" element={<FleetPage />} />
                <Route path="/property/:hotelId" element={<ListPage />} />
                <Route path="/property/:hotelId/device/:name" element={<DetailPage />} />
              </Route>
            </Routes>
          </AppLayout>
        </Router>
      </AuthProvider>
    </ErrorBoundary>
  );
};

export default App;
