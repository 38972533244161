import { useState } from "react";
const useLoading = <T>(
  action: (...args: any) => Promise<any>
): [(...args: any) => Promise<T>, boolean] => {
  const [loading, setLoading] = useState(false);
  const doAction = (...args: any) => {
    setLoading(true);
    return action(...args).finally(() => setLoading(false));
  };
  return [doAction, loading];
};
export default useLoading;
