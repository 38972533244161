import React, { useCallback, useState } from "react";
import { Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { DeviceEntity } from "../../domain/device/entities";
import { Resizable } from "react-resizable";
import ReactDragListView from "react-drag-listview";
import { PAGE_SIZES } from "../../pages/DevicesList/devicesListViewModel";

interface DataTableProps {
  currentData: DeviceEntity[] | undefined;
  filteredColumns: ColumnsType<DeviceEntity>;
  onTableChangeHandler: any;
  totalFiltered: number;
  paginationInfo: any;
  goToDevice: (name: string) => void;
  dragProps: any;
}

const ResizableTitle = ({ onResize, width, ...restProps }: any) => {
  const [resizing, setResizing] = useState(false);

  const handleResize = useCallback(
    (e: any, { size }: any) => {
      setResizing(true);
      onResize(e, { size });
    },
    [onResize]
  );

  const handleResizeStop = useCallback(() => {
    setResizing(false);
  }, []);

  if (!width) {
    return <th {...restProps} />;
  }

  return (
    <Resizable
      width={width}
      height={0}
      onResize={handleResize}
      onResizeStop={handleResizeStop}
      minConstraints={[100, 0]}
      draggableOpts={{ grid: [20], enableUserSelectHack: false }}
      handle={
        <div
          onClick={(e) => {
            e.stopPropagation();
          }}
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            height: "100%",
            width: 4,
            backgroundColor: "transparent",
            cursor: "col-resize",
            zIndex: 2,
          }}
        />
      }
    >
      <th
        {...restProps}
        style={{
          width,
          opacity: resizing ? 0.5 : 1,
        }}
      />
    </Resizable>
  );
};

const DataTable: React.FC<DataTableProps> = ({
  currentData,
  filteredColumns,
  onTableChangeHandler,
  totalFiltered,
  paginationInfo,
  goToDevice,
  dragProps,
}) => {
  return (
    <ReactDragListView.DragColumn {...dragProps}>
      <Table
        scroll={{ x: "max-content" }}
        rowKey={(record) => record.info.name}
        pagination={{
          total: totalFiltered,
          showSizeChanger: true,
          showQuickJumper: true,
          defaultCurrent: 1,
          pageSizeOptions: [
            ...PAGE_SIZES,
            totalFiltered! > 50 ? totalFiltered : undefined,
          ].filter((v) => !!v && v <= totalFiltered!) as number[],
          current: paginationInfo.current || 1,
          pageSize:
            !totalFiltered || totalFiltered > +paginationInfo?.pageSize!
              ? +paginationInfo?.pageSize!
              : totalFiltered,
          position: ["topRight", "bottomRight"],
        }}
        onRow={(record) => ({
          onClick: () => goToDevice(record.info.name),
        })}
        dataSource={currentData}
        columns={filteredColumns}
        onChange={onTableChangeHandler}
        size="small"
        components={{
          header: {
            cell: ResizableTitle,
          },
        }}
      />
    </ReactDragListView.DragColumn>
  );
};

export default DataTable;
