import { DevicesAPI } from "../../services/hcnApi";
import { useTableOptionsStore } from "../../store";

export const getDevicesUseCase = async (params: { hotelId: number }) => {
  const showUnregistered =
    useTableOptionsStore.getState().showUnregisteredDevices;
  const response = await DevicesAPI.getDevices({ hotelId: params.hotelId });
  const noDisabled = response.results.filter(
    (d) => d.info.state !== 20 && (showUnregistered || !!d?.roomInfo?.roomNumber)
  );

  return { ...response.results, results: noDisabled, total: noDisabled.length };
};
