import { create } from "zustand";

export enum AccountUpdateFields {
  PASSWORD = "password",
  EMAIL = "email",
}

interface ManageAccountStore {
  isManagingAccount: boolean;
  setIsManagingAccount: (data: boolean) => void;
  isPasswordFormVisible: boolean;
  setIsPasswordFormVisible: (data: boolean) => void;
  isResetFormsVisible: boolean;
  setIsResetFormsVisible: (data: boolean) => void;
  isOTPVisible: boolean;
  setIsOTPVisible: (data: boolean) => void;
  isChangeSuccessfulVisible: boolean;
  setIsChangeSuccessfulVisible: (data: boolean) => void;
  newValue: string;
  setNewValue: (data: string) => void;
  accountFieldToUpdate: string;
  setAccountFieldToUpdate:  (data: string) => void;
}

export const useManageAccountStore = create<ManageAccountStore>((set) => ({
  isManagingAccount: false,
  isPasswordFormVisible: false,
  isResetFormsVisible: false,
  isOTPVisible: false,
  isChangeSuccessfulVisible: false,
  newValue: "",
  accountFieldToUpdate: "",

  setIsManagingAccount: (data: boolean) => set({ isManagingAccount: data }),
  setIsPasswordFormVisible: (data: boolean) =>
    set({ isPasswordFormVisible: data }),
  setIsResetFormsVisible: (data: boolean) => set({ isResetFormsVisible: data }),
  setIsOTPVisible: (data: boolean) => set({ isOTPVisible: data }),
  setIsChangeSuccessfulVisible: (data: boolean) =>
    set({ isChangeSuccessfulVisible: data }),
  setNewValue: (data: string) => set({ newValue: data }),
  setAccountFieldToUpdate: (data: string) => set({ accountFieldToUpdate: data }),
}));
