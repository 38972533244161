import { commonConfig } from "../../config";
import AuthStorage from "../../services/authStorage";
import { deleteCookie, getCookie } from "../../services/cookie";
import { UserState } from "../../store";
import { getQueryParamValue, isTokenStillValid, jwtDecode } from "../../utils/misc";

export const checkAuthTokenUseCase = (deps: {
  setUser: (user: UserState) => void;
}) => {
  let token =
      getTokenFromURL() || getTokenFromCookieAndClear() || AuthStorage.getInstance().getJWTToken();

  const validToken = token ? isTokenStillValid(token) : false;

  if (token && validToken) {
    const tokenData = jwtDecode(token);
    if (tokenData) {
      deps.setUser(tokenData.payload as UserState);
    }
    AuthStorage.getInstance().setJWTToken(token);
  }
  return validToken;
};

const getTokenFromCookieAndClear = () => {
  const token = getCookie(commonConfig.helpdeskBridgeCookieName);
  if (!token) {
    return null;
  }

  deleteCookie(commonConfig.helpdeskBridgeCookieName);
  return token;
};

const getTokenFromURL = () => {
  return getQueryParamValue(window.location.search, "token");
}
