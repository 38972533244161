import { Tag, Tooltip } from "antd";
import {
  BATTERY_LEVEL,
  DeviceEntity,
  ROOM_STAY_STATUS,
} from "../../domain/device/entities";
import BatteryLevel from "../BatteryLevel";
import {
  daysAfterNowTimestamp,
  inRange,
  removeNonNumeric,
} from "../../utils/misc";
import WifiPercentage from "../WifiPercentage";
import Status from "../Status";
import { deviceStateMapping } from "../../domain/device/models";
import { ColumnsType } from "antd/es/table";

enum SORT_ORDER {
  descend = "descend",
  ascend = "ascend",
}

const multipleSortPriority = {
  room: 0,
  floor: 1,
  status: 2,
  battery: 3,
  wifi: 4,
  expectedCO: 5,
  guestGroupCode: 6,
};

export const createColumns = (
  getColumnSearchProps: Function,
  filteredInfo: any,
  timezones: string[],
  getSortOrderColumnInfoHelper: Function
): ColumnsType<DeviceEntity> => [
  {
    title: "ROOM",
    dataIndex: "roomInfo[roomNumber]",
    key: "roomInfo[roomNumber]",
    render: (_, record) =>
      record?.roomInfo?.roomNumber && (
        <div
          onClick={(e) => {
            e.stopPropagation();
          }}
          style={{
            height: "100%",
            width: "100%",
            top: 0,
            bottom: 0,
            position: "absolute",
            left: 0,
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
          }}
        >
          <Tooltip
            key={`${record.info.name}-desc`}
            title={`${record.roomInfo.description || ""}`}
            placement="top"
            trigger={["hover", "click"]}
          >
            <div>
              <Tag>{record.roomInfo.roomNumber}</Tag>
            </div>
          </Tooltip>
        </div>
      ),
    ...getColumnSearchProps("roomInfo.roomNumber"),
    filteredValue: filteredInfo["roomInfo[roomNumber]"] || null,
    fixed: "left",

    sorter: {
      compare: (a, b, sortOrder) => {
        if (!a.roomInfo?.roomNumber) {
          return sortOrder === SORT_ORDER.ascend ? 1 : -1;
        }
        if (!b.roomInfo?.roomNumber) {
          return sortOrder === SORT_ORDER.ascend ? -1 : 1;
        }

        return (
          +(removeNonNumeric(a.roomInfo?.roomNumber || "0") || 0) -
          +(removeNonNumeric(b.roomInfo?.roomNumber || "0") || 0)
        );
      },
      multiple: multipleSortPriority.room,
    },
    sortOrder: getSortOrderColumnInfoHelper("roomInfo[roomNumber]"),
  },
  {
    title: "FLOOR",
    dataIndex: "roomInfo[floor]",
    key: "roomInfo[floor]",
    render: (_, record) =>
      record?.roomInfo?.floor && (
        <div
          onClick={(e) => {
            e.stopPropagation();
          }}
          style={{
            height: "100%",
            width: "100%",
            top: 0,
            bottom: 0,
            position: "absolute",
            left: 0,
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
          }}
        >
          <Tooltip
            key={`${record.info.name}-desc`}
            title={`${record.roomInfo.description || ""}`}
            placement="top"
            trigger={["hover", "click"]}
          >
            <div>
              <Tag>{record.roomInfo.floor}</Tag>
            </div>
          </Tooltip>
        </div>
      ),
    ...getColumnSearchProps("roomInfo.floor"),
    filteredValue: filteredInfo["roomInfo[floor]"] || null,

    sorter: {
      compare: (a, b, sortOrder) => {
        if (!a.roomInfo?.floor) {
          return sortOrder === SORT_ORDER.ascend ? 1 : -1;
        }
        if (!b.roomInfo?.floor) {
          return sortOrder === SORT_ORDER.ascend ? -1 : 1;
        }

        return (
          +(removeNonNumeric(a.roomInfo?.floor || "0") || 0) -
          +(removeNonNumeric(b.roomInfo?.floor || "0") || 0)
        );
      },
      multiple: multipleSortPriority.floor,
    },
    sortOrder: getSortOrderColumnInfoHelper("roomInfo[floor]"),
  },
  {
    title: "EXPECTED CO",
    dataIndex: "roomInfo[checkOut]",
    key: "roomInfo[checkOut]",
    render: (_, record) =>
      record?.roomInfo?.checkOut
        ? new Date(+record.roomInfo.checkOut).toLocaleDateString()
        : "Vacant",
    sorter: {
      compare: (a, b) =>
        +(a?.roomInfo?.checkOut || 0) - +(b?.roomInfo?.checkOut || 0),
      multiple: multipleSortPriority.expectedCO,
    },
    sortOrder: getSortOrderColumnInfoHelper("roomInfo[checkOut]"),
    filteredValue: filteredInfo["roomInfo[checkOut]"] || null,
    filters: Object.keys(ROOM_STAY_STATUS).map((key) => ({
      text: key,
      value: key,
    })),

    onFilter: (value, record) => {
      const date = +(record?.roomInfo?.checkOut || 0);
      const now = Date.now();
      switch (value) {
        case ROOM_STAY_STATUS.Vacant:
          return !date;
        case ROOM_STAY_STATUS.Occupied:
          return !!date;
        case ROOM_STAY_STATUS.Today:
          return !!date && date <= daysAfterNowTimestamp(1) && date > now;
        case ROOM_STAY_STATUS.Tomorrow:
          return (
            !!date &&
            date > daysAfterNowTimestamp(1) &&
            date <= daysAfterNowTimestamp(2)
          );
        case ROOM_STAY_STATUS.Future:
          return !!date && date > daysAfterNowTimestamp(2);
        case ROOM_STAY_STATUS.Overdue:
          return !!date && date < now;
        default:
          return false;
      }
    },
    filterMultiple: false,
  },
  {
    title: "SRP",
    dataIndex: "roomInfo[guestGroupCode]",
    key: "roomInfo[guestGroupCode]",
    render: (_, record) => <Tag>{record?.roomInfo?.guestGroupCode}</Tag>,
    ...getColumnSearchProps("roomInfo.guestGroupCode"),
    filteredValue: filteredInfo["roomInfo[guestGroupCode]"] || null,

    sorter: {
      compare: (a, b, sortOrder) => {
        if (!a.roomInfo?.guestGroupCode) {
          return sortOrder === SORT_ORDER.ascend ? 1 : -1;
        }
        if (!b.roomInfo?.guestGroupCode) {
          return sortOrder === SORT_ORDER.ascend ? -1 : 1;
        }

        return a.roomInfo?.guestGroupCode.localeCompare(
          b.roomInfo?.guestGroupCode
        );
      },
      multiple: multipleSortPriority.room,
    },
    sortOrder: getSortOrderColumnInfoHelper("roomInfo[guestGroupCode]"),
  },
  {
    title: "STATUS",
    dataIndex: "info[state]",
    key: "info[state]",
    render: (_, record) => <Status value={record.info.state}></Status>,
    filters: Object.entries(deviceStateMapping).map(([value, data]) => ({
      text: data.label,
      value: value,
    })),
    onFilter: (value, record) => +value === +record.info.state,
    filteredValue: filteredInfo["info[state]"] || null,
    sorter: {
      compare: (a, b) => +a.info.state - +b.info.state,
      multiple: multipleSortPriority.status,
    },
    sortOrder: getSortOrderColumnInfoHelper("info[state]"),
  },
  {
    title: "WIFI",
    dataIndex: "event[wifiSignal]",
    key: "event[wifiSignal]",
    render: (_, record) => (
      <Tooltip
        key={record.info.name}
        title="Signal Strength 0-6"
        placement="topLeft"
      >
        <div>
          <WifiPercentage wifi={record?.event?.wifiSignal} />
        </div>
      </Tooltip>
    ),
    filters: [
      {
        text: "Medium",
        value: BATTERY_LEVEL.Medium,
      },
      {
        text: "Low",
        value: BATTERY_LEVEL.Low,
      },
      {
        text: "High",
        value: BATTERY_LEVEL.High,
      },
    ],
    filteredValue: filteredInfo["event[wifiSignal]"] || null,

    onFilter: (value, record) => {
      const signal = +record?.event?.wifiSignal;
      return value === BATTERY_LEVEL.Low
        ? inRange(signal, { min: 0, max: 1 })
        : value === BATTERY_LEVEL.Medium
        ? inRange(signal, { min: 2, max: 3 })
        : inRange(signal, { min: 4, max: 6 });
    },
    filterMultiple: true,
    sortOrder: getSortOrderColumnInfoHelper("event[wifiSignal]"),
    sorter: {
      compare: (a, b) => +a.event.wifiSignal - +b.event.wifiSignal,
      multiple: multipleSortPriority.wifi,
    },
  },
  {
    title: "BATTERY",
    dataIndex: "report[batteryLevel]",
    key: "report[batteryLevel]",
    render: (_, record) => (
      <BatteryLevel battery_level={record?.report?.batteryLevel} />
    ),
    filters: [
      {
        text: "Medium",
        value: BATTERY_LEVEL.Medium,
      },
      {
        text: "Low",
        value: BATTERY_LEVEL.Low,
      },
      {
        text: "High",
        value: BATTERY_LEVEL.High,
      },
    ],
    sorter: {
      compare: (a, b) => +a?.report?.batteryLevel - +b?.report?.batteryLevel,
      multiple: multipleSortPriority.battery,
    },
    sortOrder: getSortOrderColumnInfoHelper("report[batteryLevel]"),

    onFilter: (value, record) =>
      value === BATTERY_LEVEL.Low
        ? inRange(record.report.batteryLevel, { min: 0, max: 50 })
        : value === BATTERY_LEVEL.Medium
        ? inRange(record.report.batteryLevel, { min: 51, max: 74 })
        : inRange(record.report.batteryLevel, { min: 75, max: 100 }),
    filterMultiple: true,
    filteredValue: filteredInfo["report[batteryLevel]"] || null,
  },
  {
    title: "APK VERSION",
    dataIndex: "app[version]",
    key: "app[version]",
    render: (_, record) => <Tag>{record.app.version}</Tag>,
    sorter: {
      compare: (a, b) =>
        a?.app?.version?.localeCompare(b?.app?.version, undefined, {
          numeric: true,
          sensitivity: "base",
        }),
    },
    sortOrder: getSortOrderColumnInfoHelper("app[version]"),
  },
  {
    title: "DEVICE ID",
    dataIndex: "info[name]",
    key: "info[name]",
    render: (_, record) => <Tag>{record.info.name}</Tag>,
    ...getColumnSearchProps("info.name"),
    filteredValue: filteredInfo["info[name]"] || null,
  },
  {
    title: "SERIAL NO",
    dataIndex: "info[hardwareInfo][Serial]",
    key: "info[hardwareInfo][Serial]",
    render: (_, record) => <Tag>{record?.info?.hardwareInfo?.Serial}</Tag>,
    ...getColumnSearchProps("info.hardwareInfo.Serial"),
    filteredValue: filteredInfo["info[hardwareInfo][Serial]"] || null,
  },
  {
    title: "TIMEZONE",
    dataIndex: "info[timezone_string]",
    key: "info[timezone_string]",
    render: (_, record) => <Tag>{record?.info?.timezone_string}</Tag>,
    filters: timezones.map((v) => ({ value: v, text: v })),
    filteredValue: filteredInfo["info[timezone_string]"] || null,

    onFilter: (value, record) => {
      console.log(value);
      return value === record.info.timezone_string;
    },
    filterMultiple: true,
  },
];
