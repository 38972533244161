import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import useLoading from "../../hooks/useLoading";
import { getDevicesUseCase } from "../../useCases/devices/getDevicesUseCase";
import {
  BATTERY_LEVEL,
  DEVICE_STATUS,
  DeviceEntity,
  ROOM_STAY_STATUS,
} from "../../domain/device/entities";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Input,
  Space,
  type InputRef,
  Radio,
  MenuProps,
  Checkbox,
} from "antd";
import {
  ColumnType,
  FilterDropdownProps,
  SorterResult,
} from "antd/es/table/interface";
import { SearchOutlined } from "@ant-design/icons";
import { getObjectPropByString } from "../../utils/misc";
import { NestedKeyOf } from "../../types/util";
import { IGetDevicesFilters } from "../../services/hcnApi";
import { AxiosError } from "axios";
import { TOASTS } from "../../types/toast";
import { pubSub } from "../../infrastructure/pubSub";
import { exportCsvUseCase } from "../../useCases/devices/exportCsvUseCase";
import { ColumnsType, TableProps } from "antd/es/table";
import {
  PaginationInfo,
  ROOM_FILTER_ENUM,
  usePropertyStore,
  useTableOptionsStore,
} from "../../store";
import { useAuth } from "../../contexts/auth";
import { Property } from "../../domain/device/entities/properties";
import { getPropertiesUseCase } from "../../useCases/properties/getPropertiesUseCase";
import { ChartType } from "../../components/PropertyMetrics";
import { createColumns } from "../../components/DevicesList/columns";

const useResizableColumns = (initialColumns: ColumnsType<DeviceEntity>) => {
  const [columnWidths, setColumnWidths] = useTableOptionsStore((state) => [
    state.columnWidths,
    state.setColumnWidths,
  ]);

  const handleResize = useCallback(
    (index: number) =>
      (e: any, { size }: { size: { width: number } }) => {
        const key = initialColumns[index].key as string;
        setColumnWidths({ ...columnWidths, [key]: size.width });
      },
    [initialColumns, columnWidths, setColumnWidths]
  );

  const resizableColumns = React.useMemo(
    () =>
      initialColumns.map((col, index) => ({
        ...col,
        title:
          typeof col.title === "string" ? (
            <span className="dragHandler">{col.title}</span>
          ) : (
            col.title
          ),
        onHeaderCell: (column: ColumnType<DeviceEntity>) => ({
          width: columnWidths[column.key as string] || column.width || 100,
          onResize: handleResize(index),
        }),
      })),
    [initialColumns, columnWidths, handleResize]
  );

  return resizableColumns;
};
export const PAGE_SIZES = [10, 50, 100, 200];

const getNextAllowedPageSize = (total: number, pageSize = 0) => {
  if (pageSize !== total && !PAGE_SIZES.includes(pageSize)) {
    return (
      PAGE_SIZES.find((size) => size >= pageSize && size <= total) || total
    );
  }

  return pageSize;
};

export const useDevicesListViewModel = (): {
  fetchData: (page?: number, filters?: IGetDevicesFilters) => Promise<any>;
  goToDevice: (name: string) => void;
  loading: boolean;
  totalFiltered: number;
  offset: number;
  exportCsvHandler: () => void;
  currentData?: DeviceEntity[];
  columns: ColumnsType<DeviceEntity>;
  onTableChangeHandler: TableProps<DeviceEntity>["onChange"];
  paginationInfo: PaginationInfo;
  goBack: () => void;
  property?: Property;
  initialData?: DeviceEntity[];
  onChartFilter: (data: any) => void;
  onChartSort: (data: any) => void;
  clearAllSortFiltersHandler: () => void;
  visibleColumns: string[];
  toggleColumnVisibility: (key: string) => void;
  columnVisibilityMenu: MenuProps;
  filteredColumns: ColumnsType<DeviceEntity>;
  handleResetColumns: () => void;
  dragProps: any;
} => {
  let { hotelId } = useParams<string>();
  const [property, setProperty, setProperties] = usePropertyStore((state) => [
    state.property,
    state.setProperty,
    state.setProperties,
  ]);
  const { logout } = useAuth();
  const navigate = useNavigate();
  const [data, setData] = useState<DeviceEntity[]>();
  const [currentData, setCurrentData] = useState<DeviceEntity[]>();
  const [offset, setOffset] = useState<number>(0);

  const [exportData, setExportData] = useState<DeviceEntity[]>();
  const searchInput = useRef<InputRef>(null);
  const [
    paginationInfo,
    sortedInfo,
    roomFilter,
    setPaginationInfo,
    setSortedInfo,
    setRoomFilter,
    addSort,
    filteredInfo,
    setFilteredInfo,
    setExplicitFilter,
    clearAllSortFilters,
    timezones,
    setTimezones,
    totalFiltered,
    setTotalFiltered,
    columnOrder,
    setColumnOrder,
  ] = useTableOptionsStore((state) => [
    state.paginationInfo,
    state.sortedInfo,
    state.roomFilter,
    state.setPaginationInfo,
    state.setSortedInfo,
    state.setRoomFilter,
    state.addSort,
    state.filteredInfo,
    state.setFilteredInfo,
    state.setExplicitFilter,
    state.clearAllSortFilters,
    state.timezones,
    state.setTimezones,
    state.totalFiltered,
    state.setTotalFiltered,
    state.columnOrder,
    state.setColumnOrder,
  ]);

  const getSortOrderColumnInfoHelper = useCallback(
    (key: string) => {
      const sortOption = sortedInfo.find((s) => {
        return s.columnKey === key;
      });

      return sortOption?.order || null;
    },
    [sortedInfo]
  );

  const filterByRoom = useCallback(
    (roomFilter: ROOM_FILTER_ENUM) => {
      console.log(roomFilter, "roomFilter", data);
      const filtered = data?.filter((d) =>
        roomFilter === ROOM_FILTER_ENUM.REGISTERED
          ? !!d?.roomInfo?.roomNumber
          : roomFilter === ROOM_FILTER_ENUM.EMPTY
          ? !d?.roomInfo?.roomNumber
          : true
      );
      let commonItems = [filtered, currentData]?.reduce((x, y) =>
        x?.filter((z) => y?.includes(z))
      );

      setCurrentData(filtered);
      setExportData(filtered);

      setTotalFiltered(commonItems?.length || 0);
    },
    [currentData, data, setTotalFiltered]
  );

  const getColumnSearchProps = useCallback(
    (dataIndex: NestedKeyOf<DeviceEntity>): ColumnType<DeviceEntity> => ({
      filterDropdown: (props) => (
        <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
          {dataIndex === "roomInfo.roomNumber" && (
            <Radio.Group
              onChange={(e) => {
                e.stopPropagation();
                setRoomFilter(e.target.value);
                props.confirm({ closeDropdown: false });
                filterByRoom(e.target.value);
              }}
              value={roomFilter}
            >
              <Space direction="vertical">
                <Radio value={ROOM_FILTER_ENUM.NONE}>None</Radio>
                <Radio value={ROOM_FILTER_ENUM.REGISTERED}>Registered</Radio>
                <Radio value={ROOM_FILTER_ENUM.EMPTY}>Empty</Radio>
              </Space>
            </Radio.Group>
          )}
          <br></br>
          {SearchInput(props, dataIndex)}
        </div>
      ),
      filterIcon: (filtered: boolean) => (
        <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />
      ),
      onFilter: (value, record) => {
        return getObjectPropByString(record, dataIndex)
          ?.toString()
          .toLowerCase()
          .includes((value as string).toLowerCase());
      },
      onFilterDropdownOpenChange: (visible) => {
        if (visible) {
          setTimeout(() => searchInput.current?.select(), 100);
        }
      },
    }),
    [roomFilter, data, setRoomFilter, filterByRoom]
  );

  const columns = useMemo(
    () =>
      createColumns(
        getColumnSearchProps,
        filteredInfo,
        timezones,
        getSortOrderColumnInfoHelper
      ),
    [
      getColumnSearchProps,
      filteredInfo,
      timezones,
      getSortOrderColumnInfoHelper,
    ]
  );

  const [sortableColumns, setSortableColumns] = useState(() => {
    if (columnOrder.length > 0) {
      return columnOrder
        .map((key) => columns.find((col) => col.key === key))
        .filter(Boolean) as ColumnsType<DeviceEntity>;
    }
    return columns;
  });

  useEffect(() => {
    if (columnOrder.length > 0) {
      const orderedColumns = columnOrder
        .map((key) => columns.find((col) => col.key === key))
        .filter(Boolean) as ColumnsType<DeviceEntity>;
      setSortableColumns(orderedColumns);
    } else {
      setSortableColumns(columns);
    }
  }, [columns, columnOrder]);

  const updateColumnSorters = useCallback(
    (sorter: SorterResult<DeviceEntity> | SorterResult<DeviceEntity>[]) => {
      const sortInfo = Array.isArray(sorter) ? sorter : [sorter];
      setSortableColumns((prevColumns) =>
        prevColumns.map((col) => ({
          ...col,
          sortOrder:
            sortInfo.find((s) => s.columnKey === col.key)?.order || null,
        }))
      );
    },
    []
  );

  const onTableChangeHandler: TableProps<DeviceEntity>["onChange"] = (
    pagination,
    filters,
    sorter,
    extra
  ) => {
    setFilteredInfo(filters);

    const sortedInfo = Array.isArray(sorter) ? sorter : [sorter];
    setSortedInfo(
      sortedInfo.map((s) => ({
        columnKey: s.columnKey,
        order: s.order,
        field: s.field,
      }))
    );

    const newPageSize = getNextAllowedPageSize(
      extra.currentDataSource.length,
      pagination.pageSize
    );

    setPaginationInfo({
      current: pagination.current,
      pageSize: newPageSize,
    });
    setTotalFiltered(extra.currentDataSource.length);
    setExportData(extra.currentDataSource);

    updateColumnSorters(sorter);
  };

  const onChartFilter = useCallback(
    (params: { type: ChartType; value: any }) => {
      let key;
      let value: any;
      let filtered;
      switch (params.type) {
        case ChartType.Battery:
          key = "report[batteryLevel]";
          value = `${params.value}`.toLowerCase();
          filtered = data?.filter((d) =>
            value === BATTERY_LEVEL.Low
              ? d?.event?.batteryLevel <= 50
              : value === BATTERY_LEVEL.Medium
              ? d?.event?.batteryLevel < 75
              : d?.event?.batteryLevel >= 75
          );

          break;
        case ChartType.Wifi:
          key = "event[wifiSignal]";
          value = `${params.value}`.toLowerCase();
          filtered = data?.filter((d) => {
            const wifiPercentage = +d?.event?.wifiSignal;

            return value === BATTERY_LEVEL.Low
              ? wifiPercentage <= 1
              : value === BATTERY_LEVEL.Medium
              ? wifiPercentage <= 3
              : wifiPercentage > 3;
          });
          break;
        case ChartType.Status:
          key = "info[state]";
          value = {
            Online: DEVICE_STATUS.Online,
            Offline: DEVICE_STATUS.Offline,
          }[params.value as string];
          filtered = data?.filter((d) => value === d?.info?.state);
          break;
        case ChartType.RoomStatus:
          key = "roomInfo[checkOut]";
          value = {
            Occupied: ROOM_STAY_STATUS.Occupied,
            Unoccupied: ROOM_STAY_STATUS.Vacant,
          }[params.value as string];
          filtered = data?.filter((d) =>
            value ? !!d?.roomInfo?.checkOut : !d?.roomInfo?.checkOut
          );
          break;
      }

      let commonItems = [filtered, currentData]?.reduce((x, y) =>
        x?.filter((z) => y?.includes(z))
      );

      setFilteredInfo({});
      setRoomFilter(ROOM_FILTER_ENUM.NONE);
      setExplicitFilter({ key, value: [value] });
      setTotalFiltered(commonItems?.length || 0);
      setExportData(commonItems);
    },
    [currentData]
  );
  const onChartSort = useCallback(
    (data: ChartType) => {
      const key =
        data === ChartType.Battery
          ? "report[batteryLevel]"
          : data === ChartType.Wifi
          ? "event[wifiSignal]"
          : data === ChartType.Status
          ? "info[state]"
          : "";
      addSort({ order: "descend", columnKey: key });
    },
    [addSort]
  );

  const clearAllSortFiltersHandler = useCallback(() => {
    clearAllSortFilters();
    setFilteredInfo({});
    setRoomFilter(ROOM_FILTER_ENUM.NONE);
    setCurrentData(data);
    setExportData(data);
    setTotalFiltered(data?.length || 0);
    setPaginationInfo({ current: 1, pageSize: 10 });
    // Reset column sorters
    setSortableColumns((prevColumns) =>
      prevColumns.map((col) => ({
        ...col,
        sortOrder: null,
      }))
    );
  }, [
    clearAllSortFilters,
    data,
    setFilteredInfo,
    setRoomFilter,
    setSortableColumns,
    setTotalFiltered,
  ]);

  const [fetchData, loading] = useLoading<any>(
    async (page: number = 1, filters) => {
      // setCurrentPage(page);
      // let pagination = { offset: (page - 1) * PAGE_SIZE, limit: PAGE_SIZE };
      try {
        if (!hotelId) {
          navigate("/");
          return;
        }

        const res = await getDevicesUseCase({
          hotelId: +hotelId,
        });
        if (!property || property?.id !== +hotelId) {
          const propertyResponse = await getPropertiesUseCase();
          setProperties(propertyResponse);
          const prop = propertyResponse.find((p) => p.id === +hotelId!);

          if (!prop) {
            navigate("/");
            return;
          }
          setProperty(prop);
        }
        setData(res.results as DeviceEntity[]);
        const timezones = [
          ...new Set(res.results.map((v) => v?.info?.timezone_string)),
        ];
        setTimezones(timezones);
        setCurrentData(res.results as DeviceEntity[]);
        setExportData(res.results as DeviceEntity[]);

        setOffset(0);
        setTotalFiltered(res?.results?.length);
      } catch (err) {
        console.error(err);
        if (err instanceof AxiosError && err.response?.status === 401) {
          pubSub.emit(TOASTS.ERROR, {
            message: "Session expired. Please Login.",
          });
          logout();
          navigate("/auth");
          return;
        }
        pubSub.emit(TOASTS.ERROR, {
          message: "Something went wrong",
        });
      }
    }
  );

  const handleReset = (clearFilters: () => void) => {
    setRoomFilter(ROOM_FILTER_ENUM.NONE);
    setCurrentData(data);
    setExportData(data);

    clearFilters();
  };

  const goToDevice = (deviceName: string) => {
    const url = `/property/${hotelId}/device/${deviceName}`;
    window.open(url, "_blank");
  };

  const goBack = () => {
    navigate(`/`);
  };

  const exportCsvHandler = useCallback(() => {
    if (!exportData) {
      return;
    }
    exportCsvUseCase({ data: exportData || [] });
  }, [exportData]);

  const SearchInput: (
    props: FilterDropdownProps,
    dataIndex: NestedKeyOf<DeviceEntity>
  ) => React.ReactNode = (
    { setSelectedKeys, selectedKeys, confirm, clearFilters, close },
    dataIndex
  ) => (
    <>
      <Input
        ref={searchInput}
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={(e) =>
          setSelectedKeys(e.target.value ? [e.target.value] : [])
        }
        onPressEnter={() => confirm()}
        style={{ marginBottom: 8, display: "block" }}
      />
      <Space>
        <Button
          type="primary"
          onClick={() => confirm()}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90 }}
        >
          Search
        </Button>
        <Button
          onClick={() => {
            setExplicitFilter({
              key: dataIndex
                .split(".")
                .map((v, i) => (i === 0 ? v : `[${v}]`))
                .join(""),
              value: null,
            });

            clearFilters && handleReset(clearFilters);
          }}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
        <Button
          type="link"
          size="small"
          onClick={() => {
            confirm({ closeDropdown: false });
          }}
        >
          Filter
        </Button>
        <Button
          type="link"
          size="small"
          onClick={() => {
            close();
          }}
        >
          close
        </Button>
      </Space>
    </>
  );

  const dragProps = {
    onDragEnd(fromIndex: number, toIndex: number) {
      const newVisibleColumns = [...filteredColumns];
      const [removed] = newVisibleColumns.splice(fromIndex, 1);
      newVisibleColumns.splice(toIndex, 0, removed);

      // Update the column order based on the new arrangement of visible columns
      const newColumnOrder = newVisibleColumns.map((col) => col.key as string);

      // Preserve the order of hidden columns
      const hiddenColumns = columnOrder.filter(
        (key) => !visibleColumns.includes(key)
      );
      const updatedColumnOrder = [...newColumnOrder, ...hiddenColumns];

      setColumnOrder(updatedColumnOrder);
      setSortableColumns(
        columns.sort(
          (a, b) =>
            updatedColumnOrder.indexOf(a.key as string) -
            updatedColumnOrder.indexOf(b.key as string)
        )
      );
    },
    nodeSelector: "th",
    handleSelector: ".dragHandler",
    lineClassName: "drag-line",
  };

  const resizableColumns = useResizableColumns(
    sortableColumns.map((col) => ({ ...col, width: 100 }))
  ) as ColumnsType<DeviceEntity>;

  const [visibleColumns, setVisibleColumns, setColumnWidths] =
    useTableOptionsStore((state) => [
      state.visibleColumns,
      state.setVisibleColumns,
      state.setColumnWidths,
    ]);

  const requiredColumns = [
    "roomInfo[roomNumber]",
    "roomInfo[checkOut]",
    "info[state]",
  ];

  const toggleColumnVisibility = (key: string) => {
    if (requiredColumns.includes(key)) return;
    setVisibleColumns(
      visibleColumns.includes(key)
        ? visibleColumns.filter((k) => k !== key)
        : [...visibleColumns, key]
    );
  };

  const handleResetColumns = () => {
    const originalColumnKeys = columns.map((col) => col.key as string);
    setVisibleColumns(originalColumnKeys);
    setColumnOrder(originalColumnKeys);
    const columnWidths: Record<string, number> = columns.reduce((acc, col) => {
      acc[col.key as string] = (col.width as number) || 100;
      return acc;
    }, {} as Record<string, number>);
    setColumnWidths(columnWidths);

    // Reset the sortable columns while preserving sort order
    setSortableColumns((prevColumns) => {
      const newColumns = columns.map((col) => {
        const prevColumn = prevColumns.find(
          (prevCol) => prevCol.key === col.key
        );
        return {
          ...col,
          sortOrder: prevColumn ? prevColumn.sortOrder : null,
        };
      });
      return newColumns;
    });
  };

  const columnVisibilityMenu: MenuProps = {
    items: columns.map((column) => ({
      key: column.key as string,
      label: (
        <Checkbox
          checked={visibleColumns.includes(column.key as string)}
          onChange={() => toggleColumnVisibility(column.key as string)}
          disabled={requiredColumns.includes(column.key as string)}
        >
          {column.title as string}
        </Checkbox>
      ),
    })),
  };

  const filteredColumns = resizableColumns.filter(
    (col) =>
      requiredColumns.includes(col.key as string) ||
      visibleColumns.includes(col.key as string)
  );

  useEffect(() => {
    fetchData();
  }, [hotelId]);

  return {
    goToDevice,
    loading,
    fetchData,
    totalFiltered,
    offset,
    exportCsvHandler,
    currentData,
    columns,
    onTableChangeHandler,
    paginationInfo,
    goBack,
    property,
    initialData: data,
    onChartFilter,
    onChartSort,
    clearAllSortFiltersHandler,
    visibleColumns,
    toggleColumnVisibility,
    columnVisibilityMenu,
    filteredColumns,
    handleResetColumns,
    dragProps,
  };
};
