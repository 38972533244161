import { DeviceEntity } from "../../../domain/device/entities";
import { getDeviceState } from "../../../domain/device/models";

export const headerArray = [
	"roomNumber",
	"floor",
	"expectedCheckout",
	"guestGroupCode",
	"status",
	"wifiStrength",
	"batteryPercentage",
	"apkVersion",
	"deviceId",
	"serialNumber",
	"timezone",
	"wifiMacAddress",
	"wifiName",
];

export const mapper = (device: DeviceEntity) => [
	device?.roomInfo?.roomNumber,
	device?.roomInfo?.floor,
	device?.roomInfo?.checkOut
		? new Date(+device.roomInfo.checkOut).toLocaleDateString()
		: "Vacant",
	device?.roomInfo?.guestGroupCode || "",
	getDeviceState(device?.info?.state).description,
	device?.event?.wifiSignal,
	device?.event?.batteryLevel || device.report?.batteryLevel,
	device?.app?.version || "",
	device?.info?.name,
	device?.info?.hardwareInfo?.Serial,
	device?.info?.timezone_string || "",
	device?.info?.macAddress,
	device?.report?.wifi_ssid,
];
