import React, { useEffect, useState } from "react";
import { Modal, Button, Input } from "antd";
import {
  REGISTER_ACTION_STATE,
  RegisterHandlerParams,
  SubmitRegisterAction,
} from "../../../pages/DeviceDetails/deviceDetailsViewModel";

interface RegisterRoomModalProps {
  onSubmit: (
    params: RegisterHandlerParams
  ) => Promise<SubmitRegisterAction | undefined>;
  onCancel: () => void;
  open: boolean;
  hotelData: { id: string; name: string };
  macAddress: string;
}
const RegisterRoomModal = ({
  onSubmit,
  onCancel,
  open,
  hotelData,
  macAddress,
}: RegisterRoomModalProps) => {
  const [floor, setFloor] = useState<string | undefined>(undefined);
  const [room, setRoom] = useState<string | undefined>(undefined);
  const [description, setDescription] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const [askForStrategy, setAskForStrategy] = useState(false);
  const [existingMacAddress, setExistingMacAddress] = useState<string>();

  const clearState = () => {
    setFloor(undefined);
    setRoom(undefined);
    setLoading(false);
    setDescription(undefined);
    setAskForStrategy(false);
  };

  const registerHandler = async () => {
    if (room && floor) {
      setLoading(true);
      const res = await onSubmit({ floor, room, description });
      if (res?.status === REGISTER_ACTION_STATE.HAS_ONE_DEVICE) {
        setAskForStrategy(true);
        setExistingMacAddress(res.macAddress);
      }
      setLoading(false);
    }
  };

  const handleExplicitRegister = async (force: boolean) => {
    setLoading(true);
    if (room && floor) {
      await onSubmit({
        floor,
        room,
        force,
        description,
        askedForStrategy: true,
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!open) {
      clearState();
    }
  }, [open]);

  return !askForStrategy ? (
    <Modal
      open={open}
      closable={false}
      footer={[
        <Button
          key="confirm"
          disabled={!floor || !room || loading}
          onClick={() => registerHandler()}
        >
          Confirm
        </Button>,
        <Button
          key="cancel"
          onClick={() => {
            clearState();
            onCancel();
          }}
        >
          Cancel
        </Button>,
      ]}
    >
      <p>
        Name: {hotelData.name}, ID: {hotelData.id}
      </p>
      <p>MacAddress: {macAddress}</p>
      <Input
        style={{ margin: "20px 0 10px 0 " }}
        placeholder="Room"
        prefix={<p>room</p>}
        type="text"
        onChange={(e) => setRoom(e.target.value)}
        value={room}
      />
      <Input
        style={{ margin: "10px 0 10px 0 " }}
        placeholder="Floor"
        prefix={<p>floor</p>}
        type="text"
        onChange={(e) => setFloor(e.target.value)}
        value={floor}
      />
      <Input
        style={{ margin: "10px 0 10px 0 " }}
        maxLength={25}
        placeholder="Description (optional)"
        onChange={(input) => setDescription(input.target.value)}
      />
    </Modal>
  ) : (
    <Modal
      open={open}
      closable={false}
      footer={[
        <Button
          key="replace"
          disabled={!floor || !room || loading}
          onClick={() => handleExplicitRegister(true)}
        >
          Replace device
        </Button>,
        <Button
          key="add"
          disabled={!floor || !room || loading}
          onClick={() => handleExplicitRegister(false)}
        >
          Add new device
        </Button>,
        <Button
          key="cancel"
          onClick={() => {
            clearState();
            onCancel();
          }}
        >
          Cancel
        </Button>,
      ]}
    >
      <p>There is already a device in this room.</p>
      <p>Existing device macAddress: {existingMacAddress}</p>
    </Modal>
  );
};

export default RegisterRoomModal;
