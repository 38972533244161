import React from "react";
import { Button } from "../../components/Button";
import { RedoOutlined } from "@ant-design/icons";
import { Space, Divider, Table, Tag, Typography } from "antd";
import { Loader } from "../../components/Loader";
import { TableDataType, useFleetViewModel } from "./fleetViewModel";
import { ColumnsType } from "antd/es/table";
import TagWithColor from "../../components/TagWithColor";
import WifiPercentage from "../../components/WifiPercentage";
const { Title } = Typography;
const FleetPage = () => {
  const { data, goToProperty, loading, fetchData } = useFleetViewModel();
  const columns: ColumnsType<TableDataType> = [
    {
      title: "REGION",
      dataIndex: "name",
      key: "name",
      render: (_, record) =>
        record?.name && (
          <span
            style={{
              ...(record?.children?.length > 0 && { fontWeight: "bold" }),
            }}
          >
            {record.name}
          </span>
        ),
    },
    {
      title: "CODE",
      dataIndex: "code",
      key: "code",
      render: (_, record) => record?.code && <Tag>{record.code}</Tag>,
    },
    {
      title: "STATUS",
      dataIndex: "status",
      key: "status",
      render: (_, record, index) => {
        const percentage = record?.onlinePercentage!;
        return (
          record?.onlinePercentage && (
            <TagWithColor
              colorCodingFn={(val) =>
                val < 65 ? "red" : val <= 85 ? "yellow" : "green"
              }
              value={percentage}
            >
              {percentage < 65 ? "Offline" : "Online"}
            </TagWithColor>
          )
        );
      },
    },
    {
      title: "BATTERY",
      dataIndex: "batteryAverage",
      key: "batteryAverage",
      render: (_, record) => {
        const percentage = record?.batteryAverage;
        return (
          record?.batteryAverage && (
            <TagWithColor
              colorCodingFn={(val) =>
                val < 50 ? "red" : val <= 75 ? "yellow" : "green"
              }
              value={percentage}
            >
              {percentage < 50 ? "Low" : percentage <= 75 ? "Medium" : "Online"}
            </TagWithColor>
          )
        );
      },
    },
    {
      title: "WI-FI",
      dataIndex: "wifiAverage",
      key: "wifiAverage",
      render: (_, record) => <WifiPercentage wifi={record.wifiAverage} />,
    },
    // {
    //   render: (_, record) => ">",
    // },
  ];
  return loading ? (
    <Loader />
  ) : (
    <div style={{ background: "white", padding: "20px", top: 0 }}>
      <Space style={{ width: "100%", justifyContent: "space-between" }}>
        <Title level={2}>Tablet's fleet</Title>
        <Button onClick={() => fetchData()}>
          <RedoOutlined /> Refresh
        </Button>
      </Space>
      <Divider />
      <Table
        scroll={{ x: "max-content" }}
        rowKey={(record) =>
          record.children ? `city_${record.id}` : `hotel${record.id}`
        }
        onRow={(record) => {
          return {
            onClick: () => {
              // city case
              if (record?.children?.length > 0) {
                return;
              }

              goToProperty(record);
            },
          };
        }}
        pagination={false}
        dataSource={data}
        columns={columns}
        // onChange={onTableChangeHandler}
      />
    </div>
  );
};

export default FleetPage;
