import TagWithColor from "../TagWithColor";

const WifiPercentage: React.FC<{ wifi?: number }> = ({
  wifi,
}: {
  wifi?: number;
}) => {
  if (!wifi) {
    return null;
  }
  return (
    <TagWithColor
      colorCodingFn={(val) => (val < 2 ? "red" : val < 4 ? "yellow" : "green")}
      value={wifi}
    >
      {wifi}
    </TagWithColor>
  );
};

export default WifiPercentage;
