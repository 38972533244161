export const generateCsvBlob = <T>(params: {
  data: T[];
  mapper: (d: T) => (string | number)[];
  delimiter?: string;
  headerData: string[];
}): Blob => {
  const { data, mapper, delimiter = ",", headerData } = params;
  const csv = data.map((device) =>
    mapper(device)
      .map((p) => p || "")
      .join(delimiter)
  );
  csv.unshift(headerData.join(delimiter));

  const blob = new Blob([csv.join("\n")], { type: "text/csv;charset=utf-8;" });
  return blob;
};
