import { Checkbox, Divider, Dropdown, Space } from "antd";
import { useDevicesListViewModel } from "./devicesListViewModel";
import { Loader } from "../../components/Loader";
import {
  ClearOutlined,
  ExportOutlined,
  LeftOutlined,
  RedoOutlined,
  DownOutlined,
} from "@ant-design/icons";
import { Button } from "../../components/Button";
import Title from "antd/es/typography/Title";
import PropertyMetrics from "../../components/PropertyMetrics";
import { useTableOptionsStore } from "../../store";
import DataTable from "../../components/DevicesList/DataTable";
import { useMemo } from "react";

const ListPage = () => {
  const {
    goToDevice,
    loading,
    fetchData,
    totalFiltered,
    onTableChangeHandler,
    exportCsvHandler,
    currentData,
    paginationInfo,
    goBack,
    property,
    initialData,
    onChartFilter,
    onChartSort,
    clearAllSortFiltersHandler,
    columnVisibilityMenu,
    filteredColumns,
    handleResetColumns,
    dragProps,
  } = useDevicesListViewModel();

  const [showUnregisteredDevices, setShowUnregisteredDevices] =
    useTableOptionsStore((state) => [
      state.showUnregisteredDevices,
      state.setShowUnregisteredDevices,
    ]);

  const memoizedInitialData = useMemo(() => initialData, [initialData]);

  return loading ? (
    <Loader />
  ) : (
    <div style={{ background: "white", padding: "20px", top: 0 }}>
      <Space
        style={{
          width: "100%",
          justifyContent: "space-between",
          flexWrap: "wrap",
        }}
      >
        <Space>
          <LeftOutlined
            style={{ fontSize: "30px", padding: "20px", paddingLeft: "0px" }}
            onClick={goBack}
          />

          <Title level={2}>{property?.name}</Title>
        </Space>
        <Space
          style={{
            width: "100%",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <Button onClick={() => exportCsvHandler()}>
            <ExportOutlined /> Export CSV
          </Button>
          <Button onClick={() => clearAllSortFiltersHandler()}>
            <ClearOutlined /> Clear Sort/Filters
          </Button>
          <Button
            onClick={() => {
              handleResetColumns();
            }}
          >
            <ClearOutlined /> Reset Columns
          </Button>
          <Button onClick={() => fetchData()}>
            <RedoOutlined /> Refresh
          </Button>
          <Checkbox
            checked={showUnregisteredDevices}
            disabled={loading}
            onChange={(e) => {
              setShowUnregisteredDevices(e.target.checked);
              fetchData();
            }}
          >
            Show unregistered devices
          </Checkbox>
        </Space>
      </Space>
      <Divider />
      {memoizedInitialData && (
        <PropertyMetrics
          data={memoizedInitialData}
          onFilter={onChartFilter}
          onSort={onChartSort}
        />
      )}
      <div style={{ position: "relative" }}>
        <div
          style={{
            position: "absolute",
            top: 13,
            left: 0,
            zIndex: 10,
          }}
        >
          <Dropdown menu={columnVisibilityMenu} trigger={["click"]}>
            <Button onClick={() => {}}>
              Columns <DownOutlined />
            </Button>
          </Dropdown>
        </div>
        <DataTable
          currentData={currentData}
          filteredColumns={filteredColumns}
          onTableChangeHandler={onTableChangeHandler}
          totalFiltered={totalFiltered}
          paginationInfo={paginationInfo}
          goToDevice={goToDevice}
          dragProps={dragProps}
        />
      </div>
    </div>
  );
};

export default ListPage;
