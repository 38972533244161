import { NestedKeyOf } from "../types/util";

export const convertSignalToPercentage = (signal: string) => {
  const dB = parseFloat(signal);

  const minSignal = -100;
  const maxSignal = -50;

  const percentage = ((dB - minSignal) / (maxSignal - minSignal)) * 100;

  return Math.max(0, Math.min(100, percentage));
};

export const getObjectPropByString = <ObjectType extends object>(
  obj: ObjectType,
  propString: NestedKeyOf<ObjectType>
): any => {
  const keys = propString.split(".");
  let result = obj;
  for (const key of keys) {
    result = result[key as keyof ObjectType] as any;
    if (!result) break;
  }
  return result;
};
type Seconds = number;
interface JWTData {
  raw: string;
  header: {
    alg: string;
    typ: string;
  };
  payload: {
    client_id: string;
    user_id: number;
    user_name: string;
    is_admin: boolean;
    exp: Seconds;
  };
}
export const jwtDecode = (t: string): JWTData => {
  let token: any = {};
  token.raw = t;
  token.header = JSON.parse(window.atob(t.split(".")[0]));
  token.payload = JSON.parse(window.atob(t.split(".")[1]));
  return token;
};
export const isTokenStillValid = (token: string) => {
  const tokenData = jwtDecode(token);
  const expDateMilliseconds = tokenData.payload.exp * 1000;
  const now = Date.now();
  const expDate = new Date(expDateMilliseconds).getTime();
  const valid = now < expDate;
  return valid;
};
export const getPercentage = (val: number, max: number) =>
  ((val / max) * 100).toFixed(2);

export const inRange = (
  value: number,
  { min, max }: { min: number; max: number }
) => value <= max && value >= min;

export const removeNonNumeric = (str: string) => str.replace(/\D/g, "");

export const daysAfterNowTimestamp = (days: number) =>
  +new Date(Date.now() + days * 24 * 60 * 60 * 1000);

export const getQueryParamValue = (url: string, paramName: string) => {
  const paramRegex = new RegExp(`[?&]${paramName}=([^&]*)`);
  const match = url.match(paramRegex);
  return match ? decodeURIComponent(match[1]) : null;
}
