import { useRef, useState } from "react";
import { Input, Button, Form, Typography, Spin } from "antd";
import type { InputRef } from "antd";
import {
  AccountUpdateFields,
  useAuthStore,
  useManageAccountStore,
} from "../../../store";
import { preUpdatePasswordUseCase } from "../../../useCases/userProfile/preUpdatePasswordUseCase";
import { verifyPasswordUseCase } from "../../../useCases/userProfile/verifyPasswordUseCase";
import { pubSub } from "../../../infrastructure/pubSub";
import { TOASTS } from "../../../types/toast";

const { Text } = Typography;

const PasswordResetForm = () => {
  const [isCalling, setIsCalling] = useState<boolean>(false);
  const newPasswordRef = useRef<InputRef | null>(null);
  const confirmNewPasswordRef = useRef<InputRef | null>(null);

  const {
    setIsResetFormsVisible,
    setIsOTPVisible,
    setNewValue,
    setAccountFieldToUpdate,
  } = useManageAccountStore();
  const { userProfile } = useAuthStore();

  const lastLoginDate = userProfile?.meta?.lastLoginDate
    ? new Date(userProfile.meta.lastLoginDate)
    : null;
  const formattedLastLoginDate = lastLoginDate
    ? lastLoginDate.toLocaleString("en-US", {
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      })
    : null;
  const passExpirationDate = userProfile?.meta?.passwordExpires
    ? new Date(userProfile.meta.passwordExpires)
    : null;
  const formattedPassExpirationDate = passExpirationDate
    ? passExpirationDate.toLocaleDateString("en-US", {
        year: "numeric",
        month: "short",
        day: "numeric",
      })
    : null;

  const handleSubmit = async (values: any) => {
    const { newPassword } = values;

    if (!newPassword) return;

    setIsCalling(true);
    // prohibit update with the same password   
    const passwordVerified = await verifyPasswordUseCase({
      password: newPassword,
    });
    if (passwordVerified) {
      pubSub.emit(TOASTS.ERROR, { message: "Please provide a new password" });
      setIsCalling(false);
      return;
    }
    // pre update password call, triggers the OTP email    
    const res = await preUpdatePasswordUseCase();
    setIsCalling(false);

    if (!res?.success) return;

    // on success
    setAccountFieldToUpdate(AccountUpdateFields.PASSWORD);
    setIsResetFormsVisible(false);
    setNewValue(newPassword);
    setIsOTPVisible(true);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      <div
        style={{          
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
          marginBottom: 40,
        }}
      >
        {formattedLastLoginDate && (
          <Text style={{ fontSize: 14, fontWeight: 400, marginBottom: 20 }}>
            You last logged in on {formattedLastLoginDate}
          </Text>
        )}
        {formattedPassExpirationDate && (
          <Text style={{ fontSize: 14, fontWeight: 400, marginBottom: 20 }}>
            Your password expires on {formattedPassExpirationDate}
          </Text>
        )}
      </div>
      <Text style={{ fontSize: 14, fontWeight: 300, marginBottom: 20 }}>
        Reset your password
      </Text>
      <Form onFinish={handleSubmit} style={{ width: 300 }}>
        <Form.Item
          name="newPassword"
          label="New Password"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          rules={[
            { required: true, message: "Please input your new password!" },
            { min: 8, message: "Password must be at least 8 characters long!" },
          ]}
        >
          <Input.Password
            ref={newPasswordRef}
            placeholder="Enter new password"
            visibilityToggle={true}
            style={{ width: "100%" }}
          />
        </Form.Item>

        <Form.Item
          name="confirmNewPassword"
          label="Confirm Password"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          dependencies={["newPassword"]}
          rules={[
            { required: true, message: "Please confirm your new password!" },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("newPassword") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error("The two passwords do not match!")
                );
              },
            }),
          ]}
        >
          <Input.Password
            ref={confirmNewPasswordRef}
            placeholder="Confirm new password"
            visibilityToggle={true}
            style={{ width: "100%" }}
          />
        </Form.Item>

        <Form.Item>
          {isCalling ? (
            <Spin />
          ) : (
            <Button
              type="primary"
              htmlType="submit"
              style={{ width: "100%", marginBottom: 30 }}
            >
              Reset Password
            </Button>
          )}
        </Form.Item>
      </Form>
    </div>
  );
};

export default PasswordResetForm;
