export const adminApiConfig = {
  baseUrl:
    process.env.REACT_APP_ADMIN_BASE_URL || "http://localhost:3000/api/v1.0",
  basicAuthUsername: process.env.REACT_APP_BASIC_AUTH_USERNAME || "test",
  basicAuthPassword: process.env.REACT_APP_BASIC_AUTH_PASSWORD || "test",
};

export const commonConfig = {
  csvExportFileName: process.env.REACT_APP_CSV_EXPORT_FILE_NAME || "export",
  helpdeskBridgeCookieName: "hcn-admin-jwt-access-token",
  adminPortalUrl: process.env.REACT_APP_ADMIN_PORTAL_URL || "http://localhost:3012/portal"
};
