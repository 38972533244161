import { Property } from "../../domain/device/entities/properties";
import { PropertiesAPI } from "../../services/hcnApi";

export const getCitiesUseCase = async (): Promise<Property[]> => {
  const res = await PropertiesAPI.getCities();
  return res.map((c: any) => ({
    id: c.id,
    name: c.name,
  }));
};
