import { useAuth } from "../../contexts/auth";
import { useNavigate } from "react-router";
import useLoading from "../../hooks/useLoading";
import { useState } from "react";
interface ILoginCreds {
  username: string;
  password: string;
}
export const useLoginPageViewModel = (): {
  onFinish: (params: ILoginCreds) => Promise<void>;
  loading: boolean;
  setCredentials: (params: ILoginCreds) => void;
  credentials: ILoginCreds;
} => {
  const { login } = useAuth();
  const [credentials, setCredentials] = useState({
    username: "",
    password: "",
  });

  const [loginWithLoading, loading] = useLoading<{
    username: string;
    password: string;
  }>(login);
  const navigate = useNavigate();
  const onFinish = async (values: ILoginCreds) => {
    const success = await loginWithLoading(values);
    if (success) {
      navigate("/");
    }
  };

  return {
    onFinish,
    loading,
    setCredentials,
    credentials,
  };
};
