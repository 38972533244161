import { useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import { pubSub } from "../../infrastructure/pubSub";
import { TOASTS } from "../../types/toast";
const DisplayNotification = () => {
  useEffect(() => {
    pubSub.on(TOASTS.ERROR, (data: { message: string }) => {
      toast.error(data?.message || "Something went wrong...");
    });

    pubSub.on(TOASTS.INFO, (data: { message: string }) => {
      toast.info(data.message);
    });

    pubSub.on(TOASTS.LOGIN_ERROR, () => {
      toast.error("Login Error");
    });

    pubSub.on(TOASTS.DISMISS_ALL, () => {
      toast.dismiss();
    });

    return () => {
      pubSub.destroy(TOASTS.ERROR);
      pubSub.destroy(TOASTS.LOGIN_ERROR);
      pubSub.destroy(TOASTS.LOADING);
      pubSub.destroy(TOASTS.DISMISS_ALL);
    };
  }, []);

  return (
    <div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        limit={10}
      />
      {/* {toasts && <Toast type={toasts} onClose={resetToast} />} */}
    </div>
  );
};

export { DisplayNotification };
