import { DevicesAPI, IGetDeviceByNameParams } from "../../services/hcnApi";

export const getDeviceWifiHistoryUseCase = async (
  params: IGetDeviceByNameParams
) => {
  try {
    return DevicesAPI.getDeviceWifiHistory(params);
  } catch (err) {
    console.log(err);
  }
};
