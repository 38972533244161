import { create } from "zustand";
import { Property } from "../domain/device/entities/properties";

interface PropertyStore {
  property?: Property;
  properties?: Property[];
  setProperty: (property: Property) => void;
  setProperties: (properties: Property[]) => void;
  clearProperties: () => void;
}

export const usePropertyStore = create<PropertyStore>((set) => ({
  property: undefined,
  properties: undefined,
  setProperty: (property: Property) => set({ property }),
  setProperties: (properties: Property[]) => set({ properties }),
  clearProperties: () => set({ properties: undefined, property: undefined }),
}));
